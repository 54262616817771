import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { EuiFlexItem, EuiLink, EuiButton, EuiBadge } from '@elastic/eui';
import moment from 'moment';
import { get } from 'lodash';
import { ROLE_PATH } from 'constants/protect';
import Page from 'components/Page';
import Table from 'components/Table';
import {
	ADMIN,
	DOCTOR,
	SUPER_ADMIN,
	SECRETARY,
	CALL_CENTER,
	PHARMACIST,
} from 'components/roles';
import DatePicker from 'components/forms/DatePicker';
import { currencyFormat } from 'utils/helpers';
import ContactDetails from 'modules/_global/ContactDetails';
import OrderStatus from './components/OrderStatus';
import { addToast } from '../toasts/toasts.actions';
import { getOrders } from './orders.fetch';

const Orders = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { auth, role, vendor } = useSelector((state) => ({
		auth: state.auth,
		role: get(state, ROLE_PATH),
		vendor: get(state, 'auth.user.vendor'),
	}));

	const orderingEnabled = get(vendor, 'orderingEnabled');

	const [date, setDate] = useState(null);
	const [filter, setFilter] = useState('');
	const [params, setParams] = useState({ _sort: 'createdAt:DESC' });

	const { data: orders, refetch } = useQuery(['orders', params], getOrders, {
		placeholderData: [],
		onError: (err) => {
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		},
	});

	useEffect(() => {
		function refetchOrders() {
			const initParams = { _sort: 'createdAt:DESC' };

			if (role === PHARMACIST.key) {
				initParams.confirmed = true;
			}

			if (date) {
				initParams.createdAt_gte = moment(date).startOf('day').format();
				initParams.createdAt_lte = moment(date).endOf('day').format();
			}
			setParams(initParams);
		}

		refetchOrders();
		refetch();
	}, [date]);

	const clearFilters = async () => setDate(null);

	const renderToolsRight = () => [
		<EuiFlexItem key="datePicker" grow={false} justifyContent="center">
			<DatePicker
				onChange={(e) => setDate(e)}
				placeholder={t('orders.startDate')}
				selected={date}
			/>
		</EuiFlexItem>,
		<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
			<EuiButton
				color="secondary"
				iconType="broom"
				onClick={clearFilters}
			>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const renderContactDetails = (p) => {
		return p.contactDetails && p.contactDetails.length ? (
			<ContactDetails
				hideEmails={role !== DOCTOR.key || role === SECRETARY.key}
				ids={p.contactDetails}
			/>
		) : (
			<EuiBadge color="#c0e0ff">{p.mainNumber}</EuiBadge>
		);
	};

	const statuses = ['open', 'confirmed', 'completed', 'cancelled'];

	const onQueryChange = async ({ query }) => {
		const initParams = {};

		const values = query.text.length ? query.text.split(' ') : [];

		values.forEach((v) => {
			if (v.indexOf(':') > -1) {
				const [prop, value] = v.split(':');
				initParams[prop] = value;
			}
		});
		setParams(initParams);

		setFilter(query.text);
		refetch();
	};

	const search = {
		query: filter,
		box: {
			incremental: true,
		},
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'status',
				name: 'Status',
				multiSelect: false,

				options: statuses.map((status) => ({
					value: status,
					name: status,
					view: <OrderStatus status={status} />,
				})),
			},
			{
				type: 'field_value_selection',
				field: 'pickup',
				name: 'Fulfillment',
				multiSelect: false,
				options: [
					{
						value: false,
						name: 'For Delivery',
					},
					{
						value: true,
						name: 'For Pick Up',
					},
				],
			},
		],
		onChange: onQueryChange,
	};

	const columns = [
		{
			field: 'orderId',
			name: 'Code',
			sortable: true,
			render: (orderId, { id }) => (
				<Link to={`/orders/${id}`}>
					<EuiLink>{orderId}</EuiLink>
				</Link>
			),
			width: '15%',
		},
		{
			field: 'patient.firstName',
			name: 'Patient',
			sortable: true,
			render: (firstName, { patient: { lastName } }) =>
				`${firstName} ${lastName}`,
		},
		{
			field: 'patient',
			name: 'Contact Details',
			sortable: false,
			render: (patient) => renderContactDetails(patient),
		},
		{
			field: 'status',
			name: 'Status',
			sortable: true,
			render: (status) => <OrderStatus status={status} />,
			width: '15%',
		},
		{
			field: 'createdAt',
			name: 'Order Date',
			dataType: 'date',
			sortable: true,
			width: '15%',
			render: (v) =>
				`${moment(v).format('ll')} | ${moment(v).format('LT')}`,
		},
	];

	if (get(auth, 'user.vendor.priceEnabled')) {
		columns.push({
			field: 'totalAmount',
			name: 'Total Amount',
			dataType: 'number',
			sortable: true,
			render: currencyFormat,
			width: '15%',
		});
	}

	if (
		role === ADMIN.key ||
		role === SUPER_ADMIN.key ||
		role === CALL_CENTER.key
	) {
		columns.push({
			field: 'branch',
			name: 'Branch',
			sortable: true,
			render: (branch) => get(branch, 'name', 'Call Center'),
		});
	}

	if (role !== DOCTOR.key) {
		columns.push({
			field: 'pickup',
			name: 'Fulfillment',
			render: (pickup) => (pickup ? 'For Pickup' : 'Delivery'),
		});
	}

	if (!orderingEnabled) {
		return <Redirect to="/" />;
	}

	return (
		<>
			<Helmet title={t('orders.title')} />
			<Page title={t('orders.title')}>
				<Table
					columns={columns}
					itemId="orderId"
					items={orders}
					search={search}
				/>
			</Page>
		</>
	);
};

export default Orders;
