/* eslint-disable react/forbid-prop-types */
import React from 'react';

import { EuiFormControlLayout } from '@elastic/eui';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/helpers';

const DatePicker = ({ max, ...props }) => (
	<EuiFormControlLayout>
		<input
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			className="euiFieldText"
			max={formatDate(max)}
			min="1899-01-01"
			type="date"
		/>
	</EuiFormControlLayout>
);

DatePicker.propTypes = {
	max: PropTypes.any,
	onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
	max: null,
};

export default DatePicker;
