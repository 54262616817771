import React from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import {
	EuiStat,
	EuiPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
} from '@elastic/eui';

import _ from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import { ADMIN, CALL_CENTER, PHARMACIST, SUPER_ADMIN } from 'components/roles';
import protectHOC from 'components/protectHOC';

import { countErx, countOrders } from 'modules/dashboard/dashboard.fetch';

const formatTwoDigits = (digit) => `0${digit}`.slice(-2);
const newDate = new Date();
const today = `${newDate.getFullYear()}-${formatTwoDigits(
	newDate.getMonth() + 1,
)}-${formatTwoDigits(newDate.getDate())}`;

const Statistics = () => {
	const { branch, vendor } = useSelector((state) => ({
		vendor: _.get(state, 'auth.user.vendor'),
		branch: state.auth?.user?.details?.user?.branch,
	}));

	const newOrdersStatsEnabled = _.get(vendor, 'orderingEnabled');

	const interval = 2 * 60 * 1000; // every 5 minutes
	const results = useQueries([
		{
			queryKey: ['erx-new', { status: 'received', createdAt_gte: today }],
			queryFn: countErx,
			refetchInterval: interval,
		},
		// {
		// 	queryKey: ['erx-encoded', { encoded: true, createdAt_gte: today }],
		// 	queryFn: countErx,
		// 	refetchInterval: interval,
		// },
		{
			queryKey: [
				'orders-open',
				{
					status: [
						'open',
						'confirmed',
						'approved',
						'processing',
						'pendingPayment',
						'readyForShipping',
						'readyForPickup',
					],
					createdAt_gte: today,
					branch,
				},
			],
			queryFn: countOrders,
			refetchInterval: interval,
		},
		// {
		// 	queryKey: ['orders-confirmed', { status: 'confirmed' }],
		// 	queryFn: countOrders,
		// 	refetchInterval: interval,
		// },
		{
			queryKey: [
				'orders-completed',
				{
					status: ['completed'],
					createdAt_gte: today,
					branch,
				},
			],
			queryFn: countOrders,
			refetchInterval: interval,
		},
		{
			queryKey: ['erx-total', { branch }],
			queryFn: countErx,
			refetchInterval: interval,
		},
		{
			queryKey: ['orders-total', { branch }],
			queryFn: countOrders,
			refetchInterval: interval,
		},
	]);

	const [
		newRx,
		// encodedRx,
		newOrders,
		// confirmedOrders,
		completedOrders,
		totalRx,
		totalOrders,
	] = results;

	return (
		<div>
			<EuiTitle>
				<h1>Summary</h1>
			</EuiTitle>

			<EuiHorizontalRule margin="s" />
			<EuiFlexGroup>
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<EuiStat
							description="New eRx"
							isLoading={newRx.isLoading}
							reverse
							textAlign="center"
							title={newRx.data}
						/>
					</EuiPanel>
				</EuiFlexItem>
				{/* <EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Encoded eRx"
							isLoading={encodedRx.isLoading}
							reverse
							textAlign="center"
							title={encodedRx.data}
						/>
					</EuiPanel>
				</EuiFlexItem> */}
				{newOrdersStatsEnabled && (
					<>
						<EuiFlexItem className="eustat-card">
							<EuiPanel>
								<EuiStat
									description="New Orders"
									isLoading={newOrders.isLoading}
									reverse
									textAlign="center"
									title={newOrders.data}
								/>
							</EuiPanel>
						</EuiFlexItem>

						{/* <EuiFlexItem>
							<EuiPanel>
								<EuiStat
									description="Confirmed Orders"
									isLoading={confirmedOrders.isLoading}
									reverse
									textAlign="center"
									title={confirmedOrders.data}
								/>
							</EuiPanel>
						</EuiFlexItem> */}
						<EuiFlexItem className="eustat-card">
							<EuiPanel>
								<EuiStat
									description="Completed Orders"
									isLoading={completedOrders.isLoading}
									reverse
									textAlign="center"
									title={completedOrders.data}
								/>
							</EuiPanel>
						</EuiFlexItem>
					</>
				)}
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<EuiStat
							description="Total eRx"
							isLoading={totalRx.isLoading}
							reverse
							textAlign="center"
							title={totalRx.data}
						/>
					</EuiPanel>
				</EuiFlexItem>
				{newOrdersStatsEnabled && (
					<EuiFlexItem className="eustat-card">
						<EuiPanel>
							<EuiStat
								description="Total Orders"
								isLoading={totalOrders.isLoading}
								reverse
								textAlign="center"
								title={totalOrders.data}
							/>
						</EuiPanel>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
		</div>
	);
};

export default protectHOC(
	[SUPER_ADMIN.key, ADMIN.key, PHARMACIST.key, CALL_CENTER.key],
	ROLE_PATH,
)(Statistics);
