import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import {
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiFieldPassword,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
} from '@elastic/eui';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerPatientAction } from './auth.actions';
import { postPatientRegistation } from './auth.fetch';

const PatientRegistrationForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const PatientRegistrationSchema = Yup.object().shape({
		email: Yup.string()
			.email('Email is invalid')
			.required('Email is required'),
		username: Yup.string().required('Username is required'),
		password: Yup.string().required('Password is required'),
		confirmPassword: Yup.string().required('Password is required'),
	});

	const registrationMutation = useMutation(postPatientRegistation, {
		onMutate: () => {
			setError(null);
		},
		onSuccess: (data) => {
			dispatch(
				registerPatientAction({
					...data,
				}),
			);
			setSuccess(true);
		},
		onError: (err) => {
			setError(err?.message || 'Something went wrong');
		},
	});

	const formik = useFormik({
		initialValues: {
			username: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: PatientRegistrationSchema,
		validateOnBlur: true,
		onSubmit: async (values) => {
			registrationMutation.mutate(values);
		},
	});

	const { errors, handleChange, handleSubmit, values } = formik;

	const navigateToLogin = () => history.push('/login');
	const navigateToDoctorLogin = () => history.push('/doctor-login');
	const navigateToForgot = () => history.push('/forgot-password');

	return (
		<>
			<EuiText textAlign="center">
				<h3>Sign up</h3>
			</EuiText>
			<EuiSpacer size="l" />
			{error && (
				<>
					<EuiCallOut color="danger" size="s" title={error} />
					<EuiSpacer size="l" />
				</>
			)}
			{success && (
				<>
					<EuiCallOut
						color="success"
						size="s"
						title="Registration Successful!"
					>
						<p>Please check your email to confirm your account</p>
					</EuiCallOut>
					<EuiSpacer size="l" />
				</>
			)}
			{!success && (
				<>
					<EuiForm>
						<EuiFormRow
							error={errors.username}
							fullWidth
							isInvalid={!!errors.username}
							label="Username"
						>
							<EuiFieldText
								data-testid="username-input"
								fullWidth
								id="username"
								isInvalid={!!errors.username}
								name="username"
								onChange={handleChange}
								value={values.username}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.email}
							fullWidth
							isInvalid={!!errors.email}
							label="Email"
						>
							<EuiFieldText
								data-testid="email-input"
								fullWidth
								id="email"
								isInvalid={!!errors.email}
								name="email"
								onChange={handleChange}
								value={values.email}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.password}
							fullWidth
							isInvalid={!!errors.password}
							label="Password"
						>
							<EuiFieldPassword
								data-testid="password-input"
								fullWidth
								id="password"
								isInvalid={!!errors.password}
								name="password"
								onChange={handleChange}
								type="dual"
								value={values.password}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.confirmPassword}
							fullWidth
							isInvalid={!!errors.confirmPassword}
							label="Confirm Password"
						>
							<EuiFieldPassword
								data-testid="confirm-password-input"
								fullWidth
								id="confirmPassword"
								isInvalid={!!errors.confirmPassword}
								name="confirmPassword"
								onChange={handleChange}
								type="dual"
								value={values.confirmPassword}
							/>
						</EuiFormRow>
						<EuiSpacer size="l" />
						<EuiFormRow fullWidth>
							<EuiButton
								color="primary"
								data-testid="login-button"
								fill
								fullWidth
								isLoading={registrationMutation.isLoading}
								onClick={handleSubmit}
								type="submit"
							>
								Register
							</EuiButton>
						</EuiFormRow>
					</EuiForm>
					<EuiSpacer size="l" />
				</>
			)}
			<EuiFlexGroup
				direction="row"
				gutterSize="m"
				responsive={false}
				wrap
			>
				{!success && (
					<EuiFlexItem>
						<EuiButtonEmpty
							color="primary"
							onClick={navigateToForgot}
							size="xs"
						>
							Forgot Password?
						</EuiButtonEmpty>
					</EuiFlexItem>
				)}
				{!success && (
					<EuiFlexItem>
						<EuiButtonEmpty
							color="primary"
							onClick={navigateToDoctorLogin}
							size="xs"
						>
							Physician Login
						</EuiButtonEmpty>
					</EuiFlexItem>
				)}
				<EuiFlexItem>
					<EuiButtonEmpty
						color="primary"
						onClick={navigateToLogin}
						size="xs"
					>
						Back to Login
					</EuiButtonEmpty>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
};

export default PatientRegistrationForm;
