export const FIELDS_CUSTOMER_DETAILS = [
	'firstName',
	'lastName',
	'email',
	'mainNumber',
];

export const FIELDS_SHIPPING_DETAILS = [
	'receiverFirstName',
	'receiverLastName',
	'receiverEmail',
	'receiverContactNumber',
	'line1',
	'line2',
	'brgyId',
	'cityId',
];

export const FIELDS = {
	line1: 'address.line1',
	line2: 'address.line2',
	brgyId: 'address.brgyId',
	cityId: 'address.cityId',
	firstName: 'firstName',
	lastName: 'lastName',
	mainNumber: 'mainNumber',
	email: 'email',
};
