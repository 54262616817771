import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
	EuiButtonEmpty,
	EuiIcon,
	EuiPage,
	EuiPageBody,
	EuiPageSection,
	EuiPanel,
} from '@elastic/eui';
import { addToast } from 'modules/toasts/toasts.actions';
import { getOrder } from 'modules/orders/orders.fetch';
import RxPaymentForm from 'modules/validate-prescription/components/RxPaymentForm';
import { putPaymentMagpie } from 'modules/validate-prescription/validate-prescription.fetch';

export default function PatientTransaction() {
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const { orderId } = useParams();
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');

	useEffect(() => {
		if (token) {
			const putMagpie = async () => {
				await putPaymentMagpie({ token, orderId });
				window.location.replace(
					`${process.env.REACT_APP_BASE_URL}/transactions/${orderId}`,
				);
			};
			putMagpie();
		}
	}, []);

	const { data: orderData } = useQuery(
		[`order-${orderId}`, orderId],
		getOrder,
		{
			placeholderData: {},
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
		},
	);

	const renderTransaction = () => {
		if (isEmpty(orderData)) return 'Loading';

		return (
			<RxPaymentForm
				handleChanges={() => {}}
				hasChanges={false}
				onClose={() => history.push('/')}
				rx={{
					...orderData,
					order: {
						...orderData,
					},
				}}
				token={auth.jwt}
			/>
		);
	};

	return (
		<>
			<Helmet title={t('users.title')} />
			<EuiPage data-testid="auth-layout-container">
				<EuiPageBody
					style={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '0',
					}}
				>
					<EuiButtonEmpty
						onClick={() => history.push('/transactions')}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
						}}
					>
						<EuiIcon
							display="inline"
							size="s"
							style={{ marginRight: 5 }}
							type="arrowLeft"
						/>
						Back
					</EuiButtonEmpty>
					<EuiPageSection>
						<EuiPanel
							style={{
								width: 'fit-content',
								maxWidth: '100%',
								margin: '0 auto',
							}}
						>
							{renderTransaction()}
						</EuiPanel>
					</EuiPageSection>
				</EuiPageBody>
			</EuiPage>
		</>
	);
}

PatientTransaction.propTypes = {};

PatientTransaction.defaultProps = {};
