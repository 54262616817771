import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
	EuiFlexItem,
	EuiButton,
	EuiButtonEmpty,
	EuiIcon,
	EuiFlexGroup,
} from '@elastic/eui';
import { getPrescriptionsbyPatient } from 'modules/patients/patients.fetch';
import { addToast } from 'modules/toasts/toasts.actions';
import Table from 'components/Table';
import ViewPrescription from 'modules/prescriptions/ViewPrescription';
import PatientModal from 'modules/patients/PatientModal';

export default function PatientPrescriptions() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [order, setOrder] = useState({});
	const [selectedRx, setSelectedRx] = useState({
		data: null,
		visible: false,
	});

	const renderOrder = (val, data) => {
		if (!isEmpty(data.orders)) {
			return data.orders.map((item) => (
				<EuiFlexItem key={item.id} grow={false}>
					<EuiButtonEmpty
						className="__pending-order-btn"
						onClick={() => setOrder(item)}
						size="s"
					>
						{item.orderId},
					</EuiButtonEmpty>
				</EuiFlexItem>
			));
		}

		return (
			<EuiFlexItem grow={false}>
				<EuiIcon color="danger" type="cross" />
			</EuiFlexItem>
		);
	};

	const { data: prescriptions } = useQuery(
		['prescriptions', { _sort: 'createdAt:DESC' }],
		getPrescriptionsbyPatient,
		{
			placeholderData: [],
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
		},
	);

	const columnsPrescriptions = [
		{
			field: 'code',
			name: 'Prescription Code',
			render: (val) => <EuiFlexItem>{val}</EuiFlexItem>,
		},
		{
			field: 'prescriptionItems',
			name: 'Items encoded',
			render: (val) => <EuiFlexItem>{val.length}</EuiFlexItem>,
		},
		{
			field: 'attachments',
			name: 'Attachments',
			render: (val) => <EuiFlexItem>{val.length}</EuiFlexItem>,
		},
		{
			field: 'status',
			name: 'Pending Order',
			render: (val, data) => (
				<>
					<EuiFlexGroup wrap>{renderOrder(val, data)}</EuiFlexGroup>
				</>
			),
		},
		{
			field: 'createdAt',
			name: 'Date Created',
			render: (val) => (
				<EuiFlexItem>{moment(val).format('MM-DD-YYYY')}</EuiFlexItem>
			),
		},
		{
			field: 'id',
			name: 'Actions',
			render: (val, data) => (
				<EuiFlexGroup className="table-actions" gutterSize="s">
					<EuiFlexItem>
						<EuiButton
							fill
							onClick={() =>
								setSelectedRx({ data, visible: true })
							}
							size="s"
						>
							View
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							onClick={() => {
								history.push({
									pathname: '/my-orders/create',
									state: { data },
								});
							}}
							size="s"
						>
							Send to Pharmacy
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			),
		},
	];

	return (
		<>
			<Table
				className="prescription-table"
				columns={columnsPrescriptions}
				isSelectable
				itemId="orderId"
				items={prescriptions.data}
			/>
			<ViewPrescription
				data={{
					...selectedRx.data,
				}}
				onClose={() => {
					setSelectedRx({ data: null, visible: false });
				}}
				visible={selectedRx.visible}
			/>
			<PatientModal
				isOpen={!isEmpty(order)}
				onClose={() => setOrder({})}
				order={order}
			/>
		</>
	);
}
