import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { FaFilePrescription } from 'react-icons/fa';

import {
	EuiFlexItem,
	EuiTabbedContent,
	EuiSpacer,
	EuiButton,
	EuiFlexGroup,
	EuiSelect,
} from '@elastic/eui';
import moment from 'moment';
import { isEmpty, get, filter, has } from 'lodash';

import Page from 'components/Page';
import Table from 'components/Table';
import PatientHeader from 'modules/patients/PatientHeader';
import PatientModal from 'modules/patients/PatientModal';
import PatientPrescriptions from 'modules/patients/PatientPrescriptions';
import { getOrdersbyPatient } from 'modules/patients/patients.fetch';
import { useFormik } from 'formik';
import { addToast } from '../toasts/toasts.actions';

export default function PatientDashboardContent() {
	const { user } = useSelector((state) => ({
		user: get(state, 'auth.user'),
	}));
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const [order, setOrder] = useState({});
	const [sortedOrders, setSortedOrders] = useState({
		processing: [],
		readyForPickup: [],
		completed: [],
		cancelled: [],
	});

	const { handleChange, values } = useFormik({
		initialValues: { pendingOrderSelection: 'processing' },
	});

	const { pendingOrderSelection } = values;

	const { data: orders } = useQuery(
		['orders', { _sort: 'createdAt:DESC' }],
		getOrdersbyPatient,
		{
			placeholderData: [],
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
			refetchInterval: 30 * 1000, // 30 seconds
		},
	);

	const sortOrders = () => {
		if (orders && orders.data) {
			const processing = filter(orders.data, {
				status: 'processing',
			});
			const readyForPickup = filter(orders.data, {
				status: 'readyForPickup',
			});
			const cancelled = filter(orders.data, {
				status: 'cancelled',
			});
			const completed = filter(orders.data, {
				status: 'completed',
			});
			setSortedOrders({
				processing,
				readyForPickup,
				cancelled,
				completed,
			});
		}
	};

	useEffect(() => {
		sortOrders();
	}, [orders]);

	const snakeCaseToTitleCase = (string) => {
		return string
			.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
			.replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
	};

	const camelCaseToTitleCase = (string) => {
		return string
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase());
	};

	const columnsOrder = [
		{
			field: 'orderId',
			name: 'Code',
			render: (val) => <EuiFlexItem>{val}</EuiFlexItem>,
		},
		{
			field: 'branch.name',
			name: 'Fulfilling Branch',
			render: (val) => <EuiFlexItem>{val}</EuiFlexItem>,
		},
		{
			field: 'fulfillment',
			name: 'Fulfillment Type',
			render: (val) => (
				<EuiFlexItem>{snakeCaseToTitleCase(val)}</EuiFlexItem>
			),
		},
		{
			field: 'status',
			name: 'Order Status',
			render: (val) => (
				<EuiFlexItem>{camelCaseToTitleCase(val)}</EuiFlexItem>
			),
		},
		{
			field: 'createdAt',
			name: 'Date and Time',
			render: (val) => (
				<EuiFlexItem>{moment(val).format('MM-DD-YYYY')}</EuiFlexItem>
			),
		},
		{
			field: 'actions',
			name: 'Actions',
			render: (val, data) => (
				<EuiFlexGroup
					className="table-actions"
					gutterSize="s"
					responsive={false}
				>
					<EuiFlexItem>
						<EuiButton
							fill
							isDisabled={[
								'processing',
								'cancelled',
								'completed',
							].includes(data.status)}
							onClick={() => setOrder(data)}
							size="s"
						>
							View QR Code
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							isDisabled={[
								'processing',
								'cancelled',
								'completed',
							].includes(data.status)}
							onClick={() => {
								history.push({
									pathname: `/transactions/${data.id}`,
								});
							}}
							size="s"
						>
							View Transaction
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			),
		},
	];

	const selectList = [
		{
			value: 'processing',
			text: 'Pending Orders',
		},
		{
			value: 'readyForPickup',
			text: 'Ready For Pickup',
		},
		{
			value: 'cancelled',
			text: 'Cancelled',
		},
	];

	const tabs = [
		{
			id: 'prescriptions',
			name: 'Prescriptions',
			content: (
				<>
					<EuiSpacer />
					<PatientPrescriptions />,
				</>
			),
		},
		{
			id: 'orders',
			name: 'Pending Orders',
			content: (
				<>
					<EuiSpacer />

					<EuiFlexGroup
						gutterSize="sm"
						justifyContent="flexEnd"
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<EuiFlexItem
							className="mobile-padding-bottom-10"
							grow={false}
							style={{
								verticalAlign: 'middle',
								paddingRight: '5px',
							}}
						>
							<b>View/Filter:</b>
						</EuiFlexItem>
						<EuiFlexItem
							component="span"
							grow={false}
							style={{ verticalAlign: 'middle' }}
						>
							<EuiSelect
								id="pendingOrderSelection"
								name="pendingOrderSelection"
								onChange={handleChange}
								options={selectList}
								value={pendingOrderSelection}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>

					<Table
						className="patient-order-list-table"
						columns={columnsOrder}
						isSelectable
						itemId="orderId"
						items={sortedOrders?.[pendingOrderSelection]}
					/>
				</>
			),
		},
		{
			id: 'fulfilled',
			name: 'Fulfilled Orders',
			content: (
				<>
					<EuiSpacer />
					<Table
						className="patient-order-list-table"
						columns={columnsOrder}
						isSelectable
						itemId="orderId"
						items={sortedOrders?.completed}
					/>
				</>
			),
		},
	];

	return (
		<>
			<Helmet title={t('users.title')} />
			<Page>
				<PatientHeader user={user} />
				<EuiTabbedContent
					autoFocus="selected"
					initialSelectedTab={tabs[0]}
					tabs={tabs}
				/>
				{has(user, 'firstName') && (
					<EuiFlexItem
						className="floating-rx-button"
						id="rxButton"
						onClick={() => {
							history.push({
								pathname: '/my-orders/create',
							});
						}}
						style={{
							backgroundColor: 'primaryColor',
							marginBottom: '30px',
						}}
					>
						<FaFilePrescription
							className="add-rx-button"
							color="ghost"
							size={30}
						/>
					</EuiFlexItem>
				)}
			</Page>
			<PatientModal
				isOpen={!isEmpty(order)}
				onClose={() => setOrder({})}
				order={order}
			/>
		</>
	);
}
