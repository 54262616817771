/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IconContext } from 'react-icons';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider } from '@elastic/eui';

import AuthLayout from 'components/layouts/AuthLayout';
import DashboardLayout from 'components/layouts/DashboardLayout';
import RegistrationLayout from 'components/layouts/RegistrationLayout';
import DocumentLayout from 'components/layouts/DocumentLayout';
import ValidateRxLayout from 'components/layouts/ValidateRxLayout';
import Login from 'modules/auth/Login';
import Dashboard from 'modules/dashboard/Dashboard';
import ForgotPassword from 'modules/auth/ForgotPassword';
import PatientLoginForm from 'modules/auth/PatientLoginForm';
import PatientRegistrationForm from 'modules/auth/PatientRegistrationForm';
import ResetPassword from 'modules/auth/ResetPassword';
import Registration from 'modules/auth/Registration';
import { attachToken } from '../utils/api';
import TermsAndConditions from './_global/TermsAndConditions';
import PrivacyPolicy from './_global/PrivacyPolicy';
import ValidateRx from './validate-prescription/ValidateRx';

const queryClient = new QueryClient();

function App() {
	useSelector(({ auth }) => auth.isLoggedIn && attachToken(auth.jwt));

	const routes = [
		{
			component: PatientLoginForm,
			exact: true,
			id: 'login',
			layout: AuthLayout,
			path: '/login',
			componentProps: {
				type: 'patient',
			},
		},
		{
			component: PatientRegistrationForm,
			exact: true,
			id: 'register',
			layout: AuthLayout,
			path: '/register',
			componentProps: {
				type: 'patient',
			},
		},
		{
			component: Login,
			exact: true,
			id: 'pharmacy-login',
			layout: AuthLayout,
			path: '/pharmacy-login',
			componentProps: {
				type: 'pharma',
			},
		},
		{
			component: Login,
			exact: true,
			id: 'doctor-login',
			layout: RegistrationLayout,
			path: '/doctor-login',
			componentProps: {
				type: 'doctor',
			},
		},
		{
			component: Registration,
			exact: true,
			id: 'doctor-register',
			layout: RegistrationLayout,
			path: '/doctor-register',
		},
		{
			component: ValidateRx,
			exact: true,
			id: 'validate-rx',
			layout: ValidateRxLayout,
			path: '/validate-rx',
		},
		{
			component: ForgotPassword,
			exact: true,
			id: 'forgot-password',
			layout: AuthLayout,
			path: '/forgot-password',
		},
		{
			component: ResetPassword,
			exact: true,
			id: 'reset-password',
			layout: AuthLayout,
			path: '/reset-password',
		},
		{
			component: TermsAndConditions,
			exact: true,
			id: 'terms-and-conditions',
			path: '/terms-and-conditions',
			layout: DocumentLayout,
		},
		{
			component: PrivacyPolicy,
			exact: true,
			id: 'privacy-policy',
			path: '/privacy-policy',
			layout: DocumentLayout,
		},
		{
			component: Dashboard,
			exact: false,
			id: 'dashboard',
			layout: DashboardLayout,
			path: '/',
		},
	];

	return (
		<EuiProvider>
			<HelmetProvider>
				<QueryClientProvider client={queryClient}>
					<div className="App">
						<IconContext.Provider value={{ color: '#888888' }}>
							<Helmet
								defaultTitle="MedAlert eRx+"
								titleTemplate="%s | MedAlert eRx+"
							/>
							<Router>
								<Switch>
									{routes.map(
										({
											component: Component,
											componentProps: compProps,
											exact,
											id,
											layout: Layout,
											path,
										}) => (
											<Route
												key={`${id}-route`}
												exact={exact}
												path={path}
												render={(history, ...props) => (
													<Layout history={history}>
														<Component
															{...props}
															{...compProps}
														/>
													</Layout>
												)}
											/>
										),
									)}
								</Switch>
							</Router>
						</IconContext.Provider>
					</div>
				</QueryClientProvider>
			</HelmetProvider>
		</EuiProvider>
	);
}

export default App;
