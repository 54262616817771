import React from 'react';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButton,
	EuiText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiImage,
	EuiSpacer,
} from '@elastic/eui';

import PropTypes from 'prop-types';

const PaymentModal = ({ branch, isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<EuiOverlayMask>
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>GCash Payment</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<EuiFlexGroup direction="column">
						<EuiFlexItem
							grow
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<EuiImage
								alt="GCash QR"
								size="s"
								url={branch.gcashQr.url}
							/>
						</EuiFlexItem>

						<EuiFlexItem
							grow
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<EuiText>
								<h4>{branch.name}</h4>
								<p>GCash Number: {branch.gcashNumber}</p>
							</EuiText>
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiSpacer />
					<EuiText>
						<h4>Instructions</h4>
						<ol>
							<li>Tap &quot;Send via QR&quot;</li>
							<li>
								Scan the QR inside the frame or upload a saved
								QR from your photos
							</li>
							<li>via Photos</li>
							<li>
								Input the necessary information on the fields
								provided, then tap &quot;Next&quot;
							</li>
							<li>
								Review the details that you provided, then tap
								&quot;Send&quot;
							</li>
							<li>
								Take a screenshot of your successful payment and
								attach as a Proof of Payment on the previous
								screen after you close this form
							</li>
						</ol>
					</EuiText>
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton fill onClick={onClose}>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

PaymentModal.propTypes = {
	branch: PropTypes.shape({
		name: PropTypes.string.isRequired,
		gcashNumber: PropTypes.string.isRequired,
		gcashQr: PropTypes.shape({
			url: PropTypes.string.isRequired,
		}),
	}).isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

PaymentModal.defaultProps = {
	isOpen: false,
};

export default PaymentModal;
