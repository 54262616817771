const OPEN = { value: 'open', text: 'Open' };
const CONFIRMED = {
	value: 'confirmed',
	text: 'Confirmed',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const PROCESSING = {
	value: 'processing',
	text: 'Processing',
	pickupAllowedNextStatus: [],
};
const PENDING_PAYMENT = {
	value: 'pendingPayment',
	text: 'Pending Payment',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const APPROVED = {
	value: 'approved',
	text: 'Approved',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const READY_FOR_SHIPPING = {
	value: 'readyForShipping',
	text: 'Ready for Shipping',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const READY_FOR_PICKUP = {
	value: 'readyForPickup',
	text: 'Ready for Pickup',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const COMPLETED = {
	value: 'completed',
	text: 'Completed',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const SERVED = {
	value: 'served',
	text: 'Served',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const RECEIVED = {
	value: 'recieved',
	text: 'Received',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const CANCELLED = {
	value: 'cancelled',
	text: 'Cancelled',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const REJECTED = {
	value: 'rejected',
	text: 'Rejected',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};

/* PICKUP ALLOWED STATUS */

OPEN.pickupAllowedNextStatus = [
	CONFIRMED.value,
	PROCESSING.value,
	CANCELLED.value,
];
CONFIRMED.pickupAllowedNextStatus = [PROCESSING.value, CANCELLED.value];
PROCESSING.pickupAllowedNextStatus = [
	// PENDING_PAYMENT.value,
	READY_FOR_PICKUP.value,
	CANCELLED.value,
];
PENDING_PAYMENT.pickupAllowedNextStatus = [
	READY_FOR_PICKUP.value,
	CANCELLED.value,
];
READY_FOR_PICKUP.pickupAllowedNextStatus = [
	// PROCESSING.value,
	COMPLETED.value,
	CANCELLED.value,
];

READY_FOR_PICKUP.deliveryAllowedNextStatus = [COMPLETED.value, CANCELLED.value];

/* DELIVERY ALLOWED STATUS */
OPEN.deliveryAllowedNextStatus = [
	CONFIRMED.value,
	PROCESSING.value,
	CANCELLED.value,
];
CONFIRMED.deliveryAllowedNextStatus = [
	PROCESSING.value,
	// PENDING_PAYMENT.value,
	CANCELLED.value,
];
PROCESSING.deliveryAllowedNextStatus = [
	READY_FOR_SHIPPING.value,
	READY_FOR_PICKUP.value,
	CANCELLED.value,
];
PENDING_PAYMENT.deliveryAllowedNextStatus = [
	// READY_FOR_SHIPPING.value,
	CANCELLED.value,
];
READY_FOR_SHIPPING.deliveryAllowedNextStatus = [
	COMPLETED.value,
	CANCELLED.value,
	SERVED.value,
];

export {
	OPEN,
	CONFIRMED,
	PROCESSING,
	PENDING_PAYMENT,
	APPROVED,
	READY_FOR_SHIPPING,
	READY_FOR_PICKUP,
	COMPLETED,
	RECEIVED,
	CANCELLED,
	REJECTED,
	SERVED,
};
