/* eslint-disable indent */
import api from 'utils/api';
import { ENDPOINTS } from 'constants/api';
import { thenCallbackRQ } from 'utils/helpers';

export const patientModel = {
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	line1: '',
	line2: '',
	city: '',
	cityId: null,
	brgy: '',
	brgyId: '',
	birthdate: null,
};

export const validatePatientOrderModel = {
	fulfillment: 'purchase_all',
	paymentMethod: 'gcash',
	patientNotes: '',
	prescriptionItems: [],
	status: 'open',
	attachments: [],
	branch: null,
	vendor: null,
	preferredBranch: null,
	preferredPickupType: '',
	pickupType: '',
};

const buildFormData = (data) => {
	const payload = {
		firstName: data.firstName,
		middleName: data.middleName,
		lastName: data.lastName,
		birthdate: data.birthdate,
		address: data.line1
			? {
					id: data.id,
					line1: data.line1,
					line2: data.line2,
					city: data.city,
					cityId: data.cityId,
					brgyId: data.brgyId,
			  }
			: null,
	};

	const contactDetails = [];
	if (data.email) {
		contactDetails.push({
			type: 'email',
			value: data.email,
		});
	}
	if (data.mobile) {
		contactDetails.push({
			type: 'mobile',
			value: data.mobile,
		});
	}

	if (contactDetails.length) payload.contactDetails = contactDetails;

	return payload;
};

export const createPatient = async (payload) => {
	return api.post('/patients', buildFormData(payload)).then(thenCallbackRQ);
};

export const searchPatients = async (params) => {
	return api
		.get('/patients', {
			params,
		})
		.then(thenCallbackRQ);
};

export const getPatients = async (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get('/patients', {
			params,
		})
		.then(thenCallbackRQ);
};

export const getPatient = async (id) => api.get(`/patients/${id}`);

export const updatePatient = async (id, payload) =>
	api.put(`/patients/${id}`, payload);

export const getPatientMe = async () => api.get('/patients/me');

export const createPrescriptionsbyPatient = async (payload) => {
	const { attachments, data } = payload;

	const formData = new FormData();
	if (payload.attachments && payload.attachments.length && data) {
		formData.append('data', JSON.stringify(data));
		attachments.forEach((file) =>
			formData.append('files.attachments', file),
		);
	}

	const response = await api.post(
		ENDPOINTS.PRESCRIPTIONS,
		payload.attachments && payload.attachments.length ? formData : data,
	);

	return response;
};

export const getPrescriptionsbyPatient = async (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	const response = await api.get(ENDPOINTS.PRESCRIPTIONS, { params });
	return response;
};

export const getOrdersbyPatient = async (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	const response = await api.get(ENDPOINTS.ORDERS, { params });
	return response;
};
