import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTitle,
	EuiSpacer,
	EuiSuperSelect,
	EuiFieldNumber,
	EuiTextArea,
	EuiDescriptionList,
} from '@elastic/eui';
import numeral from 'numeral';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import PropTypes from 'prop-types';

// import NewPatientForm from 'modules/prescriptions/components/NewPatientForm';
import PersonalInformationForm from 'components/PersonalInfomationForm';
import AddressForm from 'components/AddressForm';
import Table from 'components/Table';
import DropdownOptWSub from 'components/DropdownOptWSub';
import LineItemModal from './components/LineItemModal';
import { addToast } from '../toasts/toasts.actions';
import { createOrder } from './orders.fetch';

const addOrderSchema = Yup.object().shape({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	line1: Yup.string().required('Address is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	mobile: Yup.number()
		.typeError('Invalid mobile number')
		.required('Mobile number is required'),
	city: Yup.string().required('City is required'),
	state: Yup.string().required('Region is required'),
	postal: Yup.string().required('Postal Code is required'),
});

const AddOrder = ({ isLoading, onClose, visible }) => {
	const { t } = useTranslation();
	const [isLineModalVisible, setIsLineModalVisible] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));
	const dispatch = useDispatch();
	const priceEnabled = _.get(auth, 'user.vendor.priceEnabled');

	const initialValues = {
		firstName: '',
		middleName: '',
		lastName: '',
		email: '',
		mobile: '',
		line1: '',
		line2: '',
		brgy: '',
		brgyId: '',
		city: '',
		cityId: '',
		notes: '',
		discountType: 'percent',
		discountValue: 0,
		lineItems: [],
		totalAmount: 0,
		vatAmount: 0,
	};
	const formikBag = useFormik({
		initialValues,
		validationSchema: addOrderSchema,
		validateOnBlur: true,
		onSubmit: async (data, { resetForm }) => {
			const payload = {
				patient: {
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
					address: {
						line1: data.line1,
						line2: data.line2,
						city: data.city,
						state: data.state,
						postal: data.postal,
					},
				},
				discountType: data.discountType,
				discountValue: data.discountValue,
				vendor: data.vendor.id,
				lineItems: data.lineItems,
				orderNotes: data.orderNotes,
			};

			const contactDetails = [];
			if (data.email) {
				contactDetails.push({
					type: 'email',
					value: data.email,
				});
			}
			if (data.mobile) {
				contactDetails.push({
					type: 'mobile',
					value: data.mobile,
				});
			}

			if (contactDetails.length)
				payload.patient.contactDetails = contactDetails;

			try {
				await createOrder(payload);
			} catch (err) {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			} finally {
				onClose();
				resetForm();
			}
		},
	});

	const { handleChange, handleSubmit, resetForm, setFieldValue, values } =
		formikBag;

	const {
		discountSubtotal,
		discountType,
		discountValue,
		lineItems,
		lineItemsSubtotal,
		notes,
		totalAmount,
		vatAmount,
	} = values;

	useEffect(() => {
		async function computeValues() {
			if (lineItems && lineItems.length > -1) {
				const subtotal = lineItems.reduce(
					(a, b) => a + (b.price * b.quantity || 0),
					0,
				);
				const vat = 0.12;
				let discount = 0;
				let payment = 0;

				// eslint-disable-next-line default-case
				switch (discountType) {
					case 'percent':
						if (!(discountValue > 100)) {
							discount = subtotal * (discountValue / 100);
						}
						break;
					case 'fixed':
						if (!(discountValue > subtotal)) {
							discount = discountValue;
						}
						break;
				}

				if (!subtotal - discount < 0) {
					payment = subtotal - discount;
				} else {
					payment = subtotal;
				}

				setFieldValue('vatAmount', payment * vat);
				setFieldValue('discountSubtotal', discount);
				setFieldValue('lineItemsSubtotal', subtotal);
				setFieldValue('totalAmount', payment);
			}
		}
		computeValues();
	}, [discountValue, discountType, lineItems]);

	const addLineItem = (data) => {
		const updatedLineItems = [...lineItems, data];
		setFieldValue('lineItems', updatedLineItems);
	};

	const editLineItem = (data) => {
		setSelectedItem(data);
		setIsLineModalVisible(true);
	};

	const updateLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		// const retrievedItem = lineItems[index];
		const itemsStart = lineItems.slice(0, index);
		const itemsEnd = lineItems.slice(index + 1);

		setFieldValue('lineItems', [...itemsStart, data, ...itemsEnd]);
	};

	const removeLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		const lineItemsStart = lineItems.slice(0, index);
		const lineItemsEnd = lineItems.slice(index + 1);
		setFieldValue('lineItems', [...lineItemsStart, ...lineItemsEnd]);
	};

	const lineModal = isLineModalVisible ? (
		<LineItemModal
			addLineItem={addLineItem}
			onClose={() => {
				setIsLineModalVisible(false);
				setSelectedItem(null);
			}}
			selectedItem={selectedItem}
			updateLineItem={updateLineItem}
		/>
	) : null;

	let columns = [
		{ field: 'itemName', name: t('orders.itemName'), sortable: true },
		{
			field: 'description',
			name: t('orders.description'),
			sortable: true,
		},
		{
			field: 'product.brand',
			name: t('orders.brand'),
			sortable: true,
		},
		{
			field: 'quantity',
			name: t('orders.quantity'),
			sortable: true,
			width: '10%',
		},
	];
	if (priceEnabled) {
		columns = [
			...columns,
			{
				field: 'product.sku',
				name: t('orders.sku'),
				sortable: true,
				width: '10%',
			},
			{
				field: 'price',
				name: t('orders.price'),
				sortable: true,
				dataType: 'number',
				width: '10%',
				render: (price) => (
					<EuiText>{`P ${numeral(price).format('0,0.00')}`}</EuiText>
				),
			},
			{
				field: 'price',
				name: t('orders.subtotal'),
				render: (price, { quantity }) => (
					<EuiText>
						{`P ${numeral(quantity * price).format('0,0.00')}`}
					</EuiText>
				),
				dataType: 'number',
				width: '10%',
			},
			{
				width: '10%',
				actions: [
					{
						name: 'Edit',
						description: 'Edit',
						onClick: editLineItem,
						icon: 'pencil',
						type: 'icon',
						color: 'primary',
						isPrimary: true,
					},
					{
						name: 'Delete',
						description: 'Delete',
						onClick: removeLineItem,
						icon: 'trash',
						type: 'icon',
						color: 'danger',
						isPrimary: true,
					},
				],
			},
		];
	}

	const discountOpts = [
		{
			value: 'percent',
			inputDisplay: t('orders.percent'),
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.percentSample')}
					title={t('orders.percent')}
				/>
			),
		},
		{
			value: 'fixed',
			inputDisplay: 'Fixed Amount',
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.amountSample')}
					title={t('orders.amount')}
				/>
			),
		},
	];

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="l"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Order</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiText>
								<h3>Patient Information</h3>
							</EuiText>
							<EuiSpacer />
							<PersonalInformationForm formikBag={formikBag} />
							<EuiSpacer size="l" />
							<EuiTitle size="xs">
								<h3>Address</h3>
							</EuiTitle>
							<EuiHorizontalRule margin="m" />
							<AddressForm formikBag={formikBag} />
						</EuiFlexItem>
						{priceEnabled ? (
							<EuiFlexItem>
								<EuiText>
									<h3>Summary</h3>
								</EuiText>
								<EuiSpacer />
								<EuiDescriptionList
									listItems={[
										{
											title: t('orders.subtotal'),
											description: `P ${numeral(
												lineItemsSubtotal,
											).format('0,0.00')}`,
										},
										{
											title: t('orders.discount'),
											description: `P ${numeral(
												discountSubtotal,
											).format('0,0.00')}`,
										},
										{
											title: t('orders.vat'),
											description: `P ${numeral(
												vatAmount,
											).format('0,0.00')}`,
										},
										{
											title: t('orders.total'),
											description: `P ${numeral(
												totalAmount,
											).format('0,0.00')}`,
										},
									]}
									type="column"
								/>
							</EuiFlexItem>
						) : (
							<EuiFormRow label={t('orders.orderNotes')}>
								<EuiTextArea
									data-testid="notes-input"
									id="notes"
									name="notes"
									onChange={handleChange}
									value={notes}
								/>
							</EuiFormRow>
						)}
					</EuiFlexGroup>

					<EuiSpacer />
					<EuiHorizontalRule margin="xs" />

					<EuiSpacer size="m" />

					<EuiFlexGroup
						alignItems="center"
						justifyContent="spaceBetween"
					>
						<EuiFlexItem>
							<EuiTitle size="xs">
								<h1>{t('orders.lineItems')}</h1>
							</EuiTitle>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup
								gutterSize="s"
								justifyContent="flexEnd"
							>
								<EuiFlexItem grow={false}>
									<EuiButton
										fullWidth={false}
										iconType="plusInCircle"
										onClick={() =>
											setIsLineModalVisible(true)
										}
										size="s"
									>
										{t('orders.addLineItem')}
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiHorizontalRule margin="xs" />
					<EuiSpacer />
					<Table
						columns={columns}
						isExpandable
						isSelectable
						itemId="id"
						items={lineItems}
					/>
					<EuiSpacer />
					{priceEnabled ? (
						<>
							<EuiText>
								<h3>Other Details</h3>
							</EuiText>
							<EuiSpacer />
							<EuiFormRow label={t('orders.discountType')}>
								<EuiSuperSelect
									data-testid="discountType-input"
									id="discountType"
									// isInvalid={!!errors.discountType}
									name="discountType"
									onChange={(data) =>
										setFieldValue('discountType', data)
									}
									options={discountOpts}
									valueOfSelected={discountType}
								/>
							</EuiFormRow>
							<EuiFormRow label={t('orders.discountValue')}>
								<EuiFieldNumber
									data-testid="discountValue-input"
									id="discountValue"
									// isInvalid={!!errors.discountValue}
									name="discountValue"
									onChange={handleChange}
									value={discountValue}
								/>
							</EuiFormRow>
							<EuiFormRow label={t('orders.orderNotes')}>
								<EuiTextArea
									data-testid="notes-input"
									id="notes"
									name="notes"
									onChange={handleChange}
									value={notes}
								/>
							</EuiFormRow>
						</>
					) : null}
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading}
							iconType="crossInACircleFilled"
							onClick={() => {
								resetForm({ values: initialValues });
								onClose();
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
			{lineModal}
		</EuiFlyout>
	);
};

AddOrder.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	onClose: PropTypes.bool.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddOrder;
