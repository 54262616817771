import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EuiFlexItem, EuiLink, EuiButton, EuiFlexGroup } from '@elastic/eui';

import Page from 'components/Page';
import Table from 'components/Table';
import ContactDetails from 'modules/_global/ContactDetails';
import { getPatients } from './patients.fetch';
import { addToast } from '../toasts/toasts.actions';
import AddPatient from './AddPatient';

const Users = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [selectedItems, setSelectedItems] = useState([]);
	const [addPatientVisisble, setAddPatientVisible] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [params, setParams] = useState({ _sort: 'createdAt:DESC' });
	const { data: patients, refetch } = useQuery(
		['get-patients', params],
		getPatients,
		{
			placeholderData: [],
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
		},
	);

	const selection = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelectedItems(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selectedItems,
	};

	const handleAddPatientOnclose = () => {
		setAddPatientVisible(false);
		refetch();
	};

	const clearFilters = async () => {};

	const renderToolsLeft = () => {
		if (!selectedItems.length) {
			return null;
		}

		return (
			<EuiButton color="danger" fill={false} iconType="trash">
				Delete
			</EuiButton>
		);
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="clearFilter" grow={false}>
			<EuiButton color="disabled" iconType="broom" onClick={clearFilters}>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const search = {
		box: {
			incremental: true,
		},
		toolsLeft: renderToolsLeft(),
		toolsRight: renderToolsRight(),
		filters: [],
	};

	const columns = [
		{
			field: 'id',
			name: 'Name',
			sortable: true,
			render: (id, patient) => (
				<Link to={`/patients/${id}`}>
					<EuiLink>
						{`
							${patient.lastName}, 
							${patient.firstName} 
							${patient.middleName || ''}
						`}
					</EuiLink>
				</Link>
			),
		},
		{
			field: 'contactDetails',
			name: 'Patient Contact',
			sortable: true,
			render: (contactDetails) => (
				<ContactDetails
					ids={contactDetails.map((contact) => contact.id)}
				/>
			),
		},
		// {
		// 	actions: [
		// 		{
		// 			name: 'View',
		// 			description: 'View',
		// 			onClick: async (data) => action(data, 'view'),
		// 			icon: 'eye',
		// 			type: 'icon',
		// 			isPrimary: true,
		// 		},
		// 		{
		// 			name: 'Edit',
		// 			description: 'Edit',
		// 			onClick: (data) => action(data, 'edit'),
		// 			icon: 'pencil',
		// 			type: 'icon',
		// 			color: 'primary',
		// 			isPrimary: true,
		// 		},
		// 		{
		// 			name: 'Delete',
		// 			description: 'Delete',
		// 			onClick: (data) => action(data, 'delete'),
		// 			icon: 'trash',
		// 			type: 'icon',
		// 			color: 'blue',
		// 			isPrimary: true,
		// 		},
		// 	],
		// },
	];

	return (
		<>
			<Helmet title={t('patients.title')} />
			<Page
				headerRight={
					<EuiFlexGroup direction="row" gutterSize="m">
						<EuiFlexItem>
							<EuiButton
								color="primary"
								fill
								iconType="plusInCircleFilled"
								onClick={() => setAddPatientVisible(true)}
							>
								Add Patient
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				}
				title={t('patients.title')}
			>
				<Table
					columns={columns}
					isSelectable
					itemId="id"
					items={patients}
					search={search}
					selection={selection}
				/>

				<AddPatient
					onClose={handleAddPatientOnclose}
					visible={addPatientVisisble}
				/>
			</Page>
		</>
	);
};

export default Users;
