import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
	EuiForm,
	EuiRadioGroup,
	EuiButtonIcon,
	EuiText,
	EuiDescriptionList,
	EuiFormRow,
	EuiFormLabel,
	EuiSpacer,
	EuiLink,
	EuiButton,
	EuiFilePicker,
	EuiCheckbox,
	EuiTextArea,
	EuiPageSection,
	EuiFieldText,
} from '@elastic/eui';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { get, keys, pickBy, identity } from 'lodash';
import PropTypes from 'prop-types';
import AddressForm from 'components/AddressForm';
import VendorDropdown from 'components/forms/VendorDropdown';
import TermsModal from 'modules/auth/TermsModal';
import CityMunicipalityDropdown from 'components/forms/CityMunicipalityDropdown';
import BranchDropdown from 'components/forms/BranchDropdown';
import { buildLocalAddress, getInvalid } from 'utils/helpers';
import FilesSlider from 'components/FilesSlider';
import RxFulfillmentDropdown from 'modules/prescriptions/components/RxFulfillmentDropdown';
import PaymentMethodDropdown from 'modules/prescriptions/components/PaymentMethodDropdown';
import PrivacyModal from './PrivacyModal';
import { addToast } from '../../toasts/toasts.actions';
import {
	mapValidateRxData,
	validateRxModel,
	putConfirmRx,
	renewRX,
	getGeocode,
} from '../validate-prescription.fetch';
import Map from './Map';

const ValidateRxFormValidationSchema = yup.object().shape({
	email: yup.string().email('Invalid email').required('Email is required'),
	mobile: yup
		.number()
		.typeError('Invalid mobile number')
		.required('Mobile number is required'),
	line1: yup.string().when('preferredPickupType', {
		is: (preferredPickupType) => preferredPickupType === 'grab',
		then: yup.string().required('Street Address is required'),
	}),
	cityId: yup.string().required('City is required').nullable(),
	brgyId: yup
		.string()
		.when('preferredPickupType', {
			is: (preferredPickupType) => {
				return preferredPickupType === 'grab';
			},
			then: yup.string().required('Barangay is required'),
		})
		.nullable(),
	vendor: yup.string().required('Vendor is required').nullable(),
	branch: yup.string().nullable(),
	preferredBranch: yup
		.string()
		.when('pickup', {
			is: (pickup) => pickup !== true,
			then: yup.string().nullable(),
		})
		.when('pickup', {
			is: (pickup) => pickup === true,
			then: yup.string().nullable().required('Branch is required'),
		}),
	additionalAttachments: yup
		.array()
		.of(yup.string())
		.max(10, 'You are only allowed to upload a maximum of 10 files'),
});

const ValidateRxForm = ({ handleChanges, hasChanges, refetch, rx, token }) => {
	const dispatch = useDispatch();
	const [editableAddress, setEditableAddress] = useState(true);
	const [isLoadingAdditionalAttachments, setIsLoadingAdditionalAttachments] =
		useState(false);
	const [additionalAttachments, setAdditionalAttachments] = useState([]);
	const [reset, setReset] = useState(0);
	const [acceptTermsAndAgreement, setAcceptTermsAndAgreement] =
		useState(false);
	const [termOpen, setTermOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	const [mapCenter, setMapCenter] = useState(undefined);
	const [markerPosition, setMarkerPosition] = useState(undefined);
	const fulfillmentTypes = [
		{ id: 'branch', label: 'Branch Pickup' },
		{ id: 'grab', label: 'Grab Pickup' },
		// { id: 'delivery', label: 'Pharmacy Delivery' },
	];

	const { isLoading: isSubmitting, mutate } = useMutation(putConfirmRx, {
		onSuccess: () => {
			handleChanges(false);
			dispatch(
				addToast('Order updated', 'Changes Saved', 'success', 'check'),
			);
		},
		onError: (err) => {
			let error = {
				title: 'Error: Something went wrong',
				...err,
			};
			if (
				err.message === 'Network Error' &&
				Object.keys(get(error, 'config.data')).length === 0
			) {
				error = {
					title: 'Error',
					message:
						'Please capture and save photo into your gallery first and choose file saved photos to upload',
				};
			}

			dispatch(addToast(error.title, error.message, 'warning', 'help'));
		},
	});

	const { isLoading: isSubmittingNewOrder, mutate: mutateRenewRx } =
		useMutation(renewRX, {
			onSuccess: () => {
				refetch();
				handleChanges(false);
				dispatch(
					addToast(
						'Order updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			},
			onError: (err) => {
				dispatch(addToast('Error', err.message, 'warning', 'help'));
			},
		});

	const formikBag = useFormik({
		initialValues: {
			...validateRxModel,
			...mapValidateRxData(rx),
			pickupType: 'grab',
			preferredPickupType: 'grab',
		},
		validateOnBlur: true,
		validationSchema: ValidateRxFormValidationSchema,
		enableReinitialize: true,
		onSubmit: async (data) => {
			const payload = data;

			// eslint-disable-next-line no-unreachable
			mutate({
				...payload,
				additionalAttachments,
				token,
			});
		},
	});

	const {
		errors,
		handleSubmit,
		isSubmitting: formikSubmitting,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		const errorKeys = Object.keys(errors);
		if (errorKeys.length) {
			const errorField = document.getElementsByName(errorKeys[0])[0];
			if (errorField) {
				errorField.parentElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [formikSubmitting]);

	useEffect(() => {
		if (!hasChanges) {
			handleChanges(keys(pickBy(touched, identity)).length > 0);
		}
	}, [touched]);

	useEffect(() => {
		if (values.preferredPickupType !== 'grab') return;

		if (values) {
			let position;
			if (values.deliveryAddress) {
				position = values.deliveryAddress.coordinates;
			} else if (values.patient.address) {
				position = {
					lat: values.patient.address.geo.coordinates[1],
					lng: values.patient.address.geo.coordinates[0],
				};
			} else {
				position = null;
			}
			setMarkerPosition(position);
			setMapCenter(position);
		}
	}, [values.patient.address?.geo, values.preferredPickupType]);

	useEffect(() => {
		if (markerPosition) {
			const brgy =
				values?.brgyId === values?.patient?.address?.brgyId
					? values?.patient?.address?.brgy
					: values?.brgy;

			values.deliveryAddress = {
				address: buildLocalAddress({
					line1: values.line1,
					line2: values.line2,
					brgy,
					city: values.city,
				}),
				coordinates: {
					lat: markerPosition.lat,
					lng: markerPosition.lng,
				},
			};
		}
	}, [markerPosition]);

	useEffect(() => {
		const updateAdditionalAttachments = () => {
			setFieldTouched('additionalAttachments', true);
			setFieldValue('additionalAttachments', additionalAttachments);
		};

		updateAdditionalAttachments();
	}, [additionalAttachments]);

	const handleChangeCenter = (newValue) => {
		// setMapCenter(newValue);
		setMarkerPosition(newValue);
	};

	const setCity = (e) => {
		setFieldValue('brgy', '');
		setFieldValue('brgyId', null);

		setFieldTouched('cityId', true);
		if (e) {
			setFieldValue('city', e.name);
			return setFieldValue('cityId', e.value);
		}
		setFieldValue('city', '');
		return setFieldValue('cityId', null);
	};

	const setVendor = (value) => {
		setFieldTouched('vendor', true);
		if (value) {
			return setFieldValue('vendor', value);
		}
		return setFieldValue('vendor', null);
	};

	const setBranch = (selectedOption) => {
		setFieldTouched('branch', true);
		if (selectedOption) {
			return setFieldValue('branch', selectedOption.value);
		}
		return setFieldValue('branch', null);
	};

	const setPreferredBranch = (selectedOption) => {
		setFieldTouched('preferredBranch', true);
		if (selectedOption) {
			return setFieldValue('preferredBranch', selectedOption.value);
		}
		return setFieldValue('preferredBranch', null);
	};

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;

		if (!length) return null;

		const newAdditionalFiles = Object.keys(fileItems).map(
			(key) => event[key],
		);

		if (newAdditionalFiles.length) {
			setIsLoadingAdditionalAttachments(true);

			setAdditionalAttachments([
				...additionalAttachments,
				...newAdditionalFiles,
			]);

			setTimeout(async () => {
				setIsLoadingAdditionalAttachments(false);
			}, 1000);
		}

		return setReset(Math.random().toString(36));
	};

	const handleAcceptTermsAndAgreement = () => {
		setAcceptTermsAndAgreement(!acceptTermsAndAgreement);
	};

	const toggleTerm = () => setTermOpen(!termOpen);
	const togglePrivacy = () => setPrivacyOpen(!privacyOpen);

	const validateAddress = () => {
		setFieldTouched('brgyId');
		setFieldTouched('cityId');
		setFieldTouched('line1');

		const { brgyId, cityId, line1 } = values;
		if (!brgyId || !cityId || !line1) {
			return false;
		}
		return true;
	};

	const handleSaveAddress = async () => {
		const validAddress = validateAddress();
		if (validAddress) {
			setEditableAddress(false);
			const geocode = await getGeocode({
				brgy: values.brgy,
				city: values.city,
				line1: values.line1,
				line2: values.line2,
			});
			setMapCenter({
				lat: geocode.latitude,
				lng: geocode.longitude,
			});
			setMarkerPosition({
				lat: geocode.latitude,
				lng: geocode.longitude,
			});
		}
	};

	const renderAddress = () => {
		if (values.pickup && values.preferredPickupType !== 'grab') {
			return {
				title: (
					<EuiFormLabel>City of Residence or Workplace</EuiFormLabel>
				),
				description: (
					<CityMunicipalityDropdown
						isInvalid={getInvalid('cityId', errors, touched)}
						onChange={setCity}
						value={values.cityId}
					/>
				),
			};
		}

		return {
			title: (
				<EuiFormLabel style={{ fontSize: 13 }}>Address *</EuiFormLabel>
			),
			description: !editableAddress ? (
				<>
					<EuiText size="s">
						{get(
							values.patient,
							'address.localAddress',
							buildLocalAddress({
								line1: values?.line1,
								line2: values?.line2,
								brgy:
									values?.brgyId ===
									values?.patient?.address?.brgyId
										? values?.patient?.address?.brgy
										: values?.brgy,
								city: values?.city,
							}),
						)}{' '}
					</EuiText>
					<EuiButtonIcon
						aria-label="Edit Address"
						display="base"
						iconType="pencil"
						onClick={() => setEditableAddress(!editableAddress)}
						size="s"
					/>
				</>
			) : (
				<>
					<EuiHorizontalRule margin="s" />
					<AddressForm formikBag={formikBag} markRequired />
					<EuiSpacer size="s" />
					<EuiFlexGroup
						direction="row"
						gutterSize="s"
						justifyContent="flexEnd"
						responsive={false}
					>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								aria-label="Close Edit Address"
								color="danger"
								display="fill"
								iconType="cross"
								onClick={() => setEditableAddress(false)}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								aria-label="Save Address"
								color="success"
								display="fill"
								iconType="check"
								onClick={handleSaveAddress}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiHorizontalRule margin="s" />
				</>
			),
		};
	};

	const renderContactDetails = () => (
		<>
			<EuiFormRow
				error={touched.email && errors.email}
				isInvalid={touched.email && errors.email}
				label="Email"
			>
				<EuiFieldText
					id="email"
					isInvalid={touched.email && errors.email}
					name="email"
					onBlur={formikBag.handleBlur}
					onChange={formikBag.handleChange}
					value={values.email}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.mobile && errors.mobile}
				isInvalid={touched.mobile && errors.mobile}
				label="Mobile Number"
			>
				<EuiFieldText
					id="mobile"
					isInvalid={touched.mobile && errors.mobile}
					name="mobile"
					onBlur={formikBag.handleBlur}
					onChange={formikBag.handleChange}
					value={values.mobile}
				/>
			</EuiFormRow>
		</>
	);

	const getRxItemsList = () => {
		if (!values.prescriptionItems || !values.prescriptionItems.length) {
			return null;
		}
		const items = values.prescriptionItems
			.filter((rxItem) => rxItem.currentQty > 0)
			.map((prescriptionItem) => {
				let quantity = prescriptionItem.currentQty;

				if (prescriptionItem.currentQty === 0) {
					quantity = prescriptionItem.quantity;
				}

				let title = prescriptionItem.generic;

				if (prescriptionItem.brand) {
					title = `${title} -  ${prescriptionItem.brand}`;
				}
				return {
					title,
					description: `${
						prescriptionItem.formulation || ''
					} x${quantity}`,
				};
			});

		return (
			<EuiFormRow label="Prescription Items">
				<EuiDescriptionList
					listItems={items}
					responsive={false}
					type="column"
				/>
			</EuiFormRow>
		);
	};

	const formList = [
		{
			title: <EuiFormLabel>Patient</EuiFormLabel>,
			description: `${values.patient.firstName} ${values.patient.lastName}`,
		},
		{
			title: <EuiFormLabel>Prescription</EuiFormLabel>,
			description: `${values.code}`,
		},
	];

	if (values.status === 'partial' || values.status === 'served') {
		let message = '';

		if (values.status === 'partial') {
			message = 'Your order has been partially served.';
		}

		if (values.status === 'served') {
			message = 'Your order has been partially served.';
		}

		return (
			<EuiFlexGroup direction="column" justifyContent="spaceAround">
				<EuiFlexItem
					grow={false}
					style={{
						borderColor: 'gray',
						borderWidth: '2px',
						borderStyle: 'solid',
						padding: '10%',
					}}
				>
					<EuiText
						size="m"
						style={{ fontWeight: 'bolder', fontSize: '24px' }}
						textAlign="center"
					>
						{message}
					</EuiText>
					<EuiSpacer />
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						isLoading={isSubmittingNewOrder}
						onClick={() => mutateRenewRx(token)}
					>
						New Order
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		);
	}

	const showMap = () => {
		return (
			mapCenter && markerPosition && values.preferredPickupType === 'grab'
		);
	};

	const addressExists = () => {
		return (
			values.deliveryAddress || values.patient.address || values.vendor
		);
	};

	const buildCoordinates = () => {
		if (values.preferredPickupType === 'branch') {
			return null;
		}
		if (values.deliveryAddress) return values.deliveryAddress.coordinates;
		if (!values.patient.address) return null;
		return {
			lat: values.patient.address.geo.coordinates[1],
			lng: values.patient.address.geo.coordinates[0],
		};
	};

	return (
		<EuiPageSection>
			<EuiForm>
				<EuiTitle size="s" style={{ margin: 0 }}>
					<h4>Send RX to Pharmacy</h4>
				</EuiTitle>
				<EuiHorizontalRule margin="s" />
				<EuiRadioGroup
					idSelected={
						values.pickup ? values.preferredPickupType : 'delivery'
						// 'grab'
					}
					legend={{
						children: <span>Preferred Pickup Type</span>,
					}}
					name="pickup"
					onChange={(id) => {
						setFieldValue(
							'pickup',
							id === 'grab' || id === 'branch',
						);
						setFieldValue('preferredPickupType', id);
						setFieldValue('branch', null);
						setFieldTouched('pickup', true);
					}}
					options={fulfillmentTypes}
				/>
				<EuiHorizontalRule margin="s" />
				<EuiDescriptionList compressed listItems={formList} />
				<EuiSpacer size="m" />
				{renderContactDetails()}
				<EuiSpacer size="m" />
				<EuiDescriptionList compressed listItems={[renderAddress()]} />
				{showMap() && (
					<Map
						key={process.env.REACT_APP_MAPS}
						editDisabled={editableAddress}
						mapCenter={mapCenter}
						markerPosition={markerPosition}
						onChange={handleChangeCenter}
					/>
				)}
				<EuiSpacer size="m" />
				<EuiFormRow
					error={touched.vendor && errors.vendor}
					isInvalid={getInvalid('vendor', errors, touched)}
					label="Vendor *"
				>
					<VendorDropdown
						cityId={get(values, 'cityId', null)}
						isInvalid={getInvalid('vendor', errors, touched)}
						name="vendor"
						onChange={setVendor}
						pickup={values.pickup}
						selected={
							values.vendor && typeof values.vendor === 'object'
								? get(values.vendor, 'id', null)
								: values.vendor || null
						}
						showBranchInfo
					/>
				</EuiFormRow>
				{/* Branch selection is hidden */}
				{[].length ? (
					<EuiFormRow
						error={touched.branch && errors.branch}
						isInvalid={getInvalid('branch', errors, touched)}
						label="Branch"
					>
						<BranchDropdown
							cityId={get(values, 'cityId', null)}
							isDisabled={!values.pickup}
							isInvalid={getInvalid('branch', errors, touched)}
							name="branch"
							onChange={setBranch}
							pickup={values.pickup}
							value={values.branch}
							vendor={values.vendor}
						/>
					</EuiFormRow>
				) : null}
				{values.pickup && addressExists() && (
					<EuiFormRow
						error={
							touched.preferredBranch && errors.preferredBranch
						}
						isInvalid={getInvalid(
							'preferredBranch',
							errors,
							touched,
						)}
						label="Preferred Branch *"
					>
						<BranchDropdown
							key={values.preferredPickupType}
							cityId={get(values, 'cityId', null)}
							deliveryAddress={buildCoordinates()}
							isInvalid={getInvalid(
								'preferredBranch',
								errors,
								touched,
							)}
							name="preferredBranch"
							onChange={setPreferredBranch}
							optionCount={3}
							pickup={values.pickup}
							value={values.preferredBranch}
							vendor={values.vendor}
						/>
					</EuiFormRow>
				)}
				<EuiFormRow label="Prescription Fulfillment">
					<RxFulfillmentDropdown
						data-testid="fulfillment"
						id="fulfillment"
						name="fulfillment"
						onChange={formikBag.handleChange}
						value={values.fulfillment}
					/>
				</EuiFormRow>
				<EuiFormRow helpText="(Optional)" label="Note to Pharmacist">
					<EuiTextArea
						data-testid="patientNotes-input"
						id="patientNotes"
						name="patientNotes"
						onChange={formikBag.handleChange}
						placeholder="e.g. Change brand or quantity to be purchased"
						value={values.patientNotes}
					/>
				</EuiFormRow>
				<EuiFormRow label="Preferred Payment *">
					<PaymentMethodDropdown
						data-testid="paymentMethod"
						id="paymentMethod"
						name="paymentMethod"
						onChange={formikBag.handleChange}
						value={values.paymentMethod}
					/>
				</EuiFormRow>
				<EuiFormRow label="Attachments">
					<FilesSlider
						data={values.attachments}
						isLoading={false}
						name="attachments"
						onChange={({ name, value }) =>
							setFieldValue(name, [...value])
						}
						readOnly
					/>
				</EuiFormRow>

				<EuiSpacer />
				<EuiFormRow
					error={
						touched.additionalAttachments &&
						errors.additionalAttachments
					}
					helpText="Please attach the following if applicable, Senior Citizen ID, Senior Booklet, SukiSenior Card, PWD ID"
					isInvalid={
						touched.additionalAttachments &&
						!!errors.additionalAttachments
					}
					label="Additional Attachments"
				>
					<EuiFlexGroup direction="column" gutterSize="s">
						<EuiFlexItem>
							<EuiFilePicker
								key={reset}
								fullWidth
								id="additionalAttachmentsFilePicker"
								isInvalid={
									touched.attachments && !!errors.attachments
								}
								multiple
								onChange={handleFilePicker}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<FilesSlider
								data={values.additionalAttachments}
								isLoading={isLoadingAdditionalAttachments}
								name="additionalAttachments"
								onChange={({ value }) => {
									setAdditionalAttachments([...value]);
								}}
								readOnly
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFormRow>
				{getRxItemsList()}
				<EuiHorizontalRule margin="s" />

				<EuiFormRow>
					<EuiFlexGroup
						direction="column"
						style={{ maxWidth: '800px' }}
					>
						<EuiFlexItem>
							<EuiFlexGroup
								direction="row"
								gutterSize="s"
								justifyContent="flexEnd"
							>
								<EuiFlexItem>
									<EuiCheckbox
										checked={acceptTermsAndAgreement}
										id="agreement"
										label={
											<span
												style={{
													fontSize: 12,
												}}
											>
												I confirm that I have read and
												agree to the{' '}
												<EuiLink onClick={toggleTerm}>
													Terms & Conditions
												</EuiLink>{' '}
												and{' '}
												<EuiLink
													onClick={togglePrivacy}
												>
													Privacy Policy
												</EuiLink>
											</span>
										}
										name="agreement"
										onChange={handleAcceptTermsAndAgreement}
									/>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiButton
										ariaLabel="Send to Pharmacy"
										disabled={
											!acceptTermsAndAgreement ||
											isSubmitting
										}
										isLoading={isSubmitting}
										onClick={handleSubmit}
									>
										Send to Pharmacy
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFormRow>
			</EuiForm>
			<TermsModal isOpen={termOpen} onClose={toggleTerm} />
			<PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} />
		</EuiPageSection>
	);
};

ValidateRxForm.propTypes = {
	handleChanges: PropTypes.func,
	hasChanges: PropTypes.bool,
	refetch: PropTypes.func,
	rx: PropTypes.shape({}).isRequired,
	token: PropTypes.string.isRequired,
};

ValidateRxForm.defaultProps = {
	handleChanges: () => {},
	hasChanges: false,
	refetch: () => {},
};

export default ValidateRxForm;
