import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
	EuiImage,
	EuiPage,
	EuiPageBody,
	EuiPageSection,
	EuiPanel,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
} from '@elastic/eui';
import PropTypes from 'prop-types';
import Logo from 'assets/images/logo_small.png';
import ContactInformation from 'components/ContactInformation';

function AuthLayout({ children }) {
	const auth = useSelector((state) => state.auth);
	const history = useHistory();

	if (auth.isLoggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<EuiPage
			className="registration-layout-container"
			data-testid="auth-layout-container"
		>
			<EuiPageBody
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<EuiPageSection
					style={{
						maxWidth: '500px',
						padding: '0',
					}}
				>
					<EuiPanel>
						<EuiSpacer />
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiImage
									alt="ERx Dashboard"
									size="m"
									url={Logo}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiPageSection>{children}</EuiPageSection>
					</EuiPanel>
				</EuiPageSection>
				<EuiPageSection
					style={{
						maxWidth: '500px',
						padding: '0',
					}}
				>
					<EuiPanel className="footer">
						<EuiFlexGroup
							direction="row"
							gutterSize="none"
							responsive={false}
							wrap
						>
							<EuiFlexItem>
								<EuiButtonEmpty
									color="primary"
									onClick={() => {
										history.push('/privacy-policy');
									}}
									size="xs"
								>
									Privacy Policy
								</EuiButtonEmpty>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiButtonEmpty
									color="primary"
									onClick={() => {
										history.push('/terms-and-conditions');
									}}
									size="xs"
								>
									Terms and Conditions
								</EuiButtonEmpty>
							</EuiFlexItem>
							<EuiFlexItem>
								<ContactInformation />
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiPanel>
				</EuiPageSection>
			</EuiPageBody>
		</EuiPage>
	);
}

AuthLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthLayout;
