import FormData from 'form-data';
import api from 'utils/api';
import { thenCallbackRQ } from 'utils/helpers';
import { get } from 'lodash';
import { ENDPOINTS } from 'constants/api';
import * as yup from 'yup';
import moment from 'moment';

export const AddPrescriptionSchema = yup.object().shape(
	{
		addNewPatient: yup.boolean().default(false),
		patient: yup.string().when('addNewPatient', {
			is: (addNew) => addNew === false,
			then: yup.string().required('Patient is required'),
		}),
		firstName: yup.string().when('addNewPatient', {
			is: (addNew) => addNew === true,
			then: yup.string().required('First Name is required'),
		}),
		lastName: yup.string().when('addNewPatient', {
			is: (addNew) => addNew === true,
			then: yup.string().required('Last Name is required'),
		}),
		birthdate: yup
			.string()
			.when('addNewPatient', {
				is: (addNew) => addNew === true,
				then: yup.string().required('Birthdate is required'),
			})
			.nullable(),
		email: yup.string().email('Invalid email'),
		mobile: yup
			.number()
			.typeError('Invalid mobile number')
			.required('Mobile number is required'),
		type: yup.string().oneOf(['rx', 's2']).default('rx').required(),
		attachments: yup.array().when('generate', {
			is: (generate) => generate !== true,
			then: yup
				.array()
				.of(yup.string())
				.min(1, 'We require at least 1 attachment')
				.max(
					10,
					'You are only allowed to upload a maximum of 10 files',
				),
		}),
		sendToPatient: yup.boolean().default(false),
		sendToPharmacy: yup.boolean().default(false),
		line1: yup.string().when(['newPatientAddress', 'brgyId'], {
			is: (newPatientAddress, brgyId) =>
				newPatientAddress === true && !!brgyId === true,
			then: yup.string().required('Address Line 1 is required'),
		}),
		brgyId: yup
			.string()
			.when(['newPatientAddress', 'line1'], {
				is: (newPatientAddress, line1) =>
					newPatientAddress === true && !!line1 === true,
				then: yup.string().required('Barangay is required'),
			})
			.nullable(),
		cityId: yup
			.string()
			.when(['newPatientAddress', 'line1', 'brgyId'], {
				is: (newPatientAddress, line1, brgyId) =>
					!!newPatientAddress && (!!line1 || !!brgyId),
				then: yup.string().required('City is required'),
			})
			.nullable(),
	},
	[
		['line1', 'brgyId'],
		['line1', 'cityId'],
		['brgyId', 'cityId'],
	],
);

export const rxModel = {
	prescription: {},
	code: '',
	doctor: {},
	patient: {},
	fulfillment: '',
	paymentMethod: '',
	patientNotes: '',
	clinic: {},
	createdAt: null,
	type: 'rx',
	prescriptionItems: [],
	encoded: false,
	status: '',
	packingCode: null,
	attachments: [],
	additionalAttachments: [],
	deletedFiles: [],
	orders: [],
	branch: null,
	preferredBranch: null,
	pickup: true,
	vendor: null,
	generate: false,
	addNewPatient: false,
	firstName: '',
	lastName: '',
	birthdate: null,
	newPatientAddress: false,
	addressId: '',
	line1: '',
	line2: '',
	city: '',
	cityId: '',
	brgy: '',
	brgyId: '',
	email: '',
	mobile: '',
	confirmedDate: null,
	renewalDate: null,
	preferredPickupType: null,
	receivedDate: null,
	deliveryAddress: {},
};

export const mapRxData = (rxData) => {
	const payload = {
		...rxModel,
		prescription: rxData || {},
		code: get(rxData, 'code', ''),
		orders: get(rxData, 'orders', []),
		doctor: get(rxData, 'doctor', {}),
		patient: get(rxData, 'patient', {}),
		fulfillment: get(rxData, 'fulfillment', ''),
		paymentMethod: get(rxData, 'paymentMethod', ''),
		patientNotes: get(rxData, 'patientNotes', ''),
		clinic: get(rxData, 'clinic', {}),
		createdAt: get(rxData, 'createdAt', null),
		type: get(rxData, 'type', null),
		packingCode: get(rxData, 'packingCode', null),
		prescriptionItems: get(rxData, 'prescriptionItems', []),
		encoded: get(rxData, 'encoded'),
		status: get(rxData, 'status', null),
		attachments: get(rxData, 'attachments', []),
		additionalAttachments: get(rxData, 'additionalAttachments', []),
		branch: get(rxData, 'branch.id', null),
		preferredBranch: get(rxData, 'preferredBranch.id', null),
		pickup: get(rxData, 'pickup', true),
		vendor: get(rxData, 'vendor.id', null),
		line1: get(rxData, 'patient.address.line1', ''),
		line2: get(rxData, 'patient.address.line2', ''),
		city: get(rxData, 'patient.address.city', ''),
		cityId: get(rxData, 'patient.address.cityId', ''),
		brgyId: get(rxData, 'patient.address.brgyId', ''),
		contactDetails: get(rxData, 'patient.contactDetails', []),
		confirmedDate: get(rxData, 'confirmedDate', null),
		renewalDate: get(rxData, 'renewalDate', null),
		preferredPickupType: get(rxData, 'preferredPickupType', null),
		receivedDate: get(rxData, 'receivedDate', null),
		deliveryAddress: get(rxData, 'deliveryAddress', null),
	};

	payload.email = payload.contactDetails.find(
		(contactDetail) => contactDetail.type === 'email',
	);
	payload.mobile = payload.contactDetails.find(
		(contactDetail) => contactDetail.type === 'mobile',
	);
	delete payload.contactDetails;

	return payload;
};

export const mapRxItemFormData = (data) => {
	return {
		id: data.temp ? null : data.id,
		generic: data.generic,
		brand: data.brand,
		strength: data.strength,
		sig: data.sig,
		dr_number: get(data, 'product.drNumber', null),
		quantity: data.quantity,
		product: data.product,
	};
};

export const mapRxDataToOrderData = (data) => {
	const newLineItems = get(data, 'prescriptionItems', [])
		.filter((rxItem) =>
			rxItem.id ? rxItem.currentQty > 0 : rxItem.quantity > 0,
		)
		.map((rxItem) => {
			const lineItem = {
				itemName: rxItem.generic,
				brand: rxItem.brand,
				description: rxItem.formulation,
				quantity: rxItem.currentQty || rxItem.quantity,
				price: 0,
				rxItem: rxItem.id,
			};

			if (rxItem.id) {
				lineItem.rxItem = rxItem.id;
			}

			return lineItem;
		});

	const orderData = {
		prescription: get(data, 'id'),
		code: get(data, 'code'),
		patient: get(data, 'patient', {}),
		vendor: get(data, 'vendor', {}),
		branch: get(data, 'branch', {}),
		doctor: get(data, 'doctor'),
		status: get(data, 'status'),
		prescriptionItems: get(data, 'prescriptionItems', []),
		lineItems: newLineItems,
		amount: get(data, 'amount', 0),
		pickup: get(data, 'pickup', true),
		notes: get(data, 'notes', ''),
		pickupType: get(data, 'preferredPickupType', ''),
		fulfillment: get(data, 'fulfillment', ''),
	};
	return orderData;
};

export const updateContactDetails = ({ email, mobile }) => {
	const contactDetails = [];

	if (email) {
		contactDetails.push({
			type: 'email',
			value: email,
		});
	}

	if (mobile) {
		contactDetails.push({
			type: 'mobile',
			value: mobile,
		});
	}

	return contactDetails;
};

export const createRxBuildFormData = (data) => {
	const payload = {
		attachments: data.attachments,
		type: data.type,
		vendor: data.vendor,
		branch: data.branch,
		email: data?.email,
		mobile: data?.mobile,
	};

	if (data.generate) payload.generate = data.generate;

	if (data.prescriptionItems.length) {
		payload.prescriptionItems = data.prescriptionItems.map((item) => ({
			...item,
			product: get(item, 'product.id', null),
		}));
	}

	if (data.addNewPatient) {
		payload.patient = {
			firstName: data.firstName,
			lastName: data.lastName,
			birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
		};

		const contactDetails = [];
		if (data.email) {
			contactDetails.push({
				type: 'email',
				value: data.email,
			});
		}
		if (data.mobile) {
			contactDetails.push({
				type: 'mobile',
				value: data.mobile,
			});
		}

		if (contactDetails.length) payload.contactDetails = contactDetails;

		if (
			data.newPatientAddress &&
			!!data.line1 &&
			!!data.brgyId &&
			!!data.cityId
		) {
			payload.patient = {
				...payload.patient,
				address: {
					line1: data.line1,
					line2: data.line2,
					brgy: data.brgy,
					brgyId: data.brgyId,
					cityId: data.cityId,
					city: data.city,
				},
			};
		}
	} else {
		payload.patient = data.patient;
		if (data.newPatientAddress) {
			payload.address = {
				line1: data.line1,
				line2: data.line2,
				brgyId: data.brgyId || null,
				brgy: data.brgy,
				cityId: data.cityId || null,
				city: data.city,
			};
		}
	}

	payload.contactDetails = updateContactDetails(data);

	return payload;
};

export const buildFormData = (data) => {
	return {
		type: data.type,
		branch: data.branch,
		pickup: data.pickup,
		vendor: data.vendor,
		paymentMethod: data.paymentMethod,
		prescriptionItems: data.prescriptionItems,
	};
};

export const getRxs = async (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get(`${ENDPOINTS.PRESCRIPTIONS}`, { params })
		.then(thenCallbackRQ);
};

export const getRx = (ctx) => {
	const { queryKey } = ctx;
	const [, rxId] = queryKey;
	return api.get(`${ENDPOINTS.PRESCRIPTIONS}/${rxId}`).then(thenCallbackRQ);
};

export const updateRxStatus = async ({ id, value }) => {
	return api.put(`/prescriptions/${id}/status?status=${value}`);
};

export const updateRx = async ({ id, ...data }) => {
	const buildedFormData = buildFormData(data);
	return api.put(`/prescriptions/${id}`, buildedFormData);
};

export const getPrescriptions = async (params) => {
	return api.get('/prescriptions', { params });
};

export const getPrescription = async (id) => api.get(`/prescriptions/${id}`);

export const getPrescriptionsByParameters = async (payload) =>
	api.post(`/prescriptions/filter-by-date`, payload);

export const updatePrescription = async (id, payload) =>
	api.put(`/prescriptions/${id}`, payload);

export const updatePrescriptionStatus = async (id, status) =>
	api.put(`/prescriptions/${id}/status?status=${status}`);

export const createPrescription = async (payload) => {
	const { attachments, ...data } = createRxBuildFormData(payload);
	const formData = new FormData();

	formData.append('data', JSON.stringify(data));
	attachments.forEach((file) => formData.append('files[attachments]', file));

	return api.post('/prescriptions', formData);
};

export const getRxItem = async (id) => api.get(`/prescription-items/${id}`);

export const deleteMultiplePrescriptions = async (payload) => {
	const promises = payload.map((data) =>
		api.delete(`prescriptions/${data.id}`),
	);
	const results = await Promise.allSettled(promises);
	return results.filter((p) => p.status === 'fulfilled');
};

export const sendRxToPatient = async (id, params = {}) => {
	return api.get(`/prescriptions/${id}/send`, { params });
};

export const getSignedAttachments = async (ids) => {
	const params = {
		id_in: ids,
	};
	return api.get('/prescriptions/attachments/signed', { params });
};

export const countHistory = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get(`/prescription-transfer-histories/count`, {
			params,
		})
		.then(thenCallbackRQ);
};

export const getTransferHistory = (ctx) => {
	const { pageParam = 0, queryKey } = ctx;
	const [, queryParams] = queryKey;

	const { _limit: queryLimit, ...initParams } = queryParams;

	const paginationSize =
		queryLimit || Number(process.env.PAGINATION_SIZE) || 5;

	const params = {
		_start: pageParam * paginationSize,
		_limit: paginationSize,
		_sort: 'createdAt:ASC',
		...initParams,
	};

	return api
		.get(`/prescription-transfer-histories`, { params })
		.then(thenCallbackRQ);
};
