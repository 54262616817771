/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';

import CustomStep from 'components/Step';
import Page from 'components/Page';
import { getUserDetails } from 'modules/auth/auth.fetch';
import { updateUserDetails } from 'modules/auth/auth.actions';
import PatientOrderForm from './PatientOrderForm';

import { renewRX } from '../validate-prescription/validate-prescription.fetch';
import PatientOrderFormValidationSchema from './PatientOrder.validator';
import { addToast } from '../toasts/toasts.actions';
import {
	validatePatientOrderModel,
	createPrescriptionsbyPatient,
} from './patients.fetch';

export default function PatientOrder() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { location } = history;
	const { patientInfo } = useSelector((state) => ({
		patientInfo: state.auth.user,
	}));

	const [step, setStep] = useState(1);
	const [attachments, setAttachments] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [isSameCustomerDeets, setIsSameCustomerDeets] = useState(false);
	const [formData, setFormData] = useState({});

	const pickupTypes = ['delivery', 'grab'];

	const getStepStatus = (index) => {
		if (index === step) return 'current';
		if (step > index) return 'complete';
		return 'incomplete';
	};

	const steps = [
		{
			key: 1,
			title: 'Prescription Details',
			description: 'Choose medicines and upload Rx',
			status: getStepStatus(1),
		},
		{
			key: 2,
			title: ' Customer Information',
			description: 'Customer and Shipping Details ',
			status: getStepStatus(2),
		},
		{
			key: 3,
			title: 'Branch and Payment',
			description: 'Select preferred branch and billing',
			status: getStepStatus(3),
		},
	];

	const refreshUserDetails = async () => {
		const { data: user } = await getUserDetails();

		dispatch(updateUserDetails({ ...patientInfo, ...user?.details }));
	};

	const { isLoading: isSubmitting, mutate } = useMutation(
		createPrescriptionsbyPatient,
		{
			onSuccess: () => {
				dispatch(
					addToast(
						'Success',
						'Prescription sent!',
						'success',
						'check',
					),
				);

				refreshUserDetails();

				history.push('/');
			},
			onError: (err) => {
				let error = {
					title: 'Error: Something went wrong',
					...err,
				};
				if (
					err.message === 'Network Error' &&
					Object.keys(get(error, 'config.data')).length === 0
				) {
					error = {
						title: 'Error',
						message:
							'Please capture and save photo into your gallery first and choose file saved photos to upload',
					};
				}

				dispatch(
					addToast(error.title, error.message, 'warning', 'help'),
				);
			},
		},
	);

	const { isLoading: isSubmittingNewOrder, mutate: mutateRenewRx } =
		useMutation(renewRX, {
			onSuccess: () => {
				dispatch(
					addToast(
						'Order updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			},
			onError: (err) => {
				dispatch(addToast('Error', err.message, 'warning', 'help'));
			},
		});

	const setPayload = (values) => {
		const prescriptionItems = [];

		if (values.prescriptionItems) {
			values.prescriptionItems.forEach((item) =>
				prescriptionItems.push({
					generic: item.generic,
					brand: item.brand,
					quantity: item.quantity,
					price: 0,
				}),
			);
		}

		const payload = {
			preferredPickupType: values.pickupType,
			vendor: values.vendor,
			preferredBranch: values.preferredBranch,
			patient: values.patient,
			receiver: values.receiver,
			fulfillment: values.fulfillment,
			patientNotes: values.patientNotes,
		};

		if (prescriptionItems.length) {
			Object.assign(payload, { prescriptionItems });
		}

		return payload;
	};

	const setInitialValues = () => {
		const locationStateData = location?.state?.data || {};

		const {
			attachments: locationStateDataAttachments,
			branch,
			fulfillment,
			patientNotes,
			paymentMethod,
			prescriptionItems,
			vendor,
		} = locationStateData;

		return {
			fulfillment: fulfillment || validatePatientOrderModel.fulfillment,
			paymentMethod:
				paymentMethod || validatePatientOrderModel.paymentMethod,
			patientNotes:
				patientNotes || validatePatientOrderModel.patientNotes,
			prescriptionItems:
				prescriptionItems ||
				validatePatientOrderModel.prescriptionItems,
			status: locationStateData
				? 'open'
				: validatePatientOrderModel.status,
			attachments:
				locationStateDataAttachments ||
				validatePatientOrderModel.attachments,
			vendor: vendor?.id || validatePatientOrderModel.vendor,
			preferredBranch:
				branch?.id || validatePatientOrderModel.preferredBranch,
			pickupType: 'grab',
		};
	};

	const formikBag = useFormik({
		initialValues: setInitialValues(),
		validateOnBlur: true,
		validationSchema: PatientOrderFormValidationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			let payload = null;

			let shouldSetFormData = false;

			const files = !isEmpty(location?.state?.data)
				? location.state.data.attachments
				: null;

			const { paymentMethod } = formData;

			const address = {
				brgy: values.brgy,
				brgyId: values.brgyId,
				city: values.city ? values.city : 'fill',
				cityId: values.cityId,
				line1: values.line1,
				line2: values.line2,
			};

			const receiver = {
				id: patientInfo?.customer?.id || null,
				user: patientInfo?.user?.id,
				firstName: values.receiverFirstName,
				lastName: values.receiverLastName,
				mainNumber: values.receiverContactNumber,
				email: values.receiverEmail,
				address,
			};

			const attachmentIds = [];

			switch (true) {
				case !isEmpty(location?.state?.data):
					payload = setPayload({
						...values,
						receiver,
					});

					if (files && files.length) {
						files.forEach((file) => {
							attachmentIds.push(file.id);
						});

						Object.assign(payload, {
							attachmentIds:
								attachments?.length > 0 ? [] : attachmentIds,
						});
					}

					break;
				case pickupTypes.includes(values.pickupType):
					shouldSetFormData = true;

					payload = setPayload(formData);

					Object.assign(payload, { receiver, paymentMethod });
					break;
				default:
					payload = setPayload(values);
					break;
			}

			mutate({
				data: payload,
				attachments,
			});

			if (shouldSetFormData) {
				setFormData(values);
			}
		},
	});

	const { values } = formikBag;

	const onPrev = () => {
		if (step > 1) setStep(step - 1);
		else return null;
	};

	const onNext = () => {
		if (step < steps.length) {
			setStep(step + 1);
			window.scrollTo(0, 0);
		} else return null;
	};

	const shippingFillable = [
		'receiverFirstName',
		'receiverLastName',
		'receiverContactNumber',
		'receiverEmail',
		'cityId',
		'brgyId',
		'line1',
	];

	const step3Fillable = [
		'cityMunId',
		'vendor',
		'preferredBranch',
		'paymentMethod',
	];

	const enableNextBtn = () => {
		if (step === 1) {
			if (
				isEmpty(values.attachments) &&
				!isEmpty(values.prescriptionItems)
			) {
				setDisabled(false);
			} else if (
				!isEmpty(values.attachments) &&
				isEmpty(values.prescriptionItems)
			) {
				setDisabled(false);
			} else if (
				!isEmpty(values.attachments) &&
				!isEmpty(values.prescriptionItems)
			) {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
		} else if (step === 2) {
			if (values.pickupType === 'grab') {
				setDisabled(false);
				shippingFillable.forEach((field) => {
					const val = get(values, field);
					if (isEmpty(val)) setDisabled(true);
				});
			} else if (!isEmpty(values.pickupType)) {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
		} else if (step === 3) {
			setDisabled(false);
			step3Fillable.forEach((field) => {
				const val = get(values, field);
				if (isEmpty(val)) setDisabled(true);
			});
		}
	};

	useEffect(() => {
		enableNextBtn();

		if (values) {
			setFormData(values);
		}
	}, [values, step, disabled]);

	return (
		<>
			<Helmet title={t('users.title')} />
			<Page>
				<CustomStep steps={steps} />
				<PatientOrderForm
					disabled={disabled}
					formikBag={formikBag}
					isSameCustomerDeets={isSameCustomerDeets}
					isSubmitting={isSubmitting}
					isSubmittingNewOrder={isSubmittingNewOrder}
					mutateRenewRx={mutateRenewRx}
					onNext={onNext}
					onPrev={onPrev}
					patientInfo={patientInfo}
					setAttachments={setAttachments}
					setIsSameCustomerDeets={setIsSameCustomerDeets}
					step={step}
					steps={steps}
				/>
			</Page>
		</>
	);
}

PatientOrder.propTypes = {};

PatientOrder.defaultProps = {};
