/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	EuiFieldText,
	EuiFlexItem,
	EuiFlexGroup,
	EuiFormRow,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import CityMunicipalityDropdown from 'components/forms/CityMunicipalityDropdown';
import BarangayDropdown from 'components/forms/BarangayDropdown';

const AddressForm = ({
	formikBag,
	fullWidth,
	markRequired,
	readOnly,
	showCity,
}) => {
	const { t } = useTranslation();
	const {
		errors,
		handleBlur,
		handleChange,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	const handleCityChange = (e) => {
		if (e) {
			setFieldTouched('cityId', true);
			setFieldValue('city', e.name);
			return setFieldValue('cityId', e.value);
		}

		setFieldValue('city', '');
		return setFieldValue('cityId', null);
	};

	const handleBrgyChange = (e) => {
		if (e) {
			setFieldTouched('brgyId', true);

			if (e.city) {
				setFieldTouched('cityId', true);
				setFieldValue('city', e.city.name);
				setFieldValue('cityId', e.city.id);
			}

			setFieldValue('brgy', e.name);
			return setFieldValue('brgyId', e.value);
		}

		setFieldValue('brgy', '');
		return setFieldValue('brgyId', null);
	};

	return (
		<>
			<EuiFlexGroup
				style={{
					flexDirection: 'column',
					maxWidth: fullWidth ? '100%' : 425,
				}}
			>
				{showCity && (
					<EuiFlexItem>
						<EuiFormRow
							error={touched.cityId && errors.cityId}
							fullWidth
							isInvalid={touched.cityId && !!errors.cityId}
							label={
								markRequired
									? `${t('general.address.city')} *`
									: t('general.address.city')
							}
						>
							<CityMunicipalityDropdown
								isDisabled={readOnly}
								isInvalid={touched.cityId && !!errors.cityId}
								name="cityId"
								onChange={handleCityChange}
								value={values.cityId}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				)}
				<EuiFlexItem>
					<EuiFormRow
						error={touched.brgyId && errors.brgyId}
						fullWidth
						isInvalid={touched.brgyId && !!errors.brgyId}
						label={
							markRequired
								? `${t('general.address.barangay')} *`
								: t('general.address.city')
						}
					>
						<BarangayDropdown
							city={values.cityId}
							isDisabled={readOnly}
							isInvalid={touched.brgyId && !!errors.brgyId}
							name="brgyId"
							onChange={handleBrgyChange}
							value={values.brgyId}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.line2 && errors.line2}
						fullWidth
						isInvalid={touched.line2 && !!errors.line2}
						label={t('general.address.line2')}
					>
						<EuiFieldText
							id="line2"
							isInvalid={touched.line2 && !!errors.line2}
							name="line2"
							onBlur={(e) => {
								handleBlur(e);
								const val = (e.target.value || '').replace(
									/\s+/gi,
									' ',
								);
								setFieldValue('line2', val.trim());
							}}
							onChange={handleChange}
							readOnly={readOnly}
							value={values.line2}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.line1 && errors.line1}
						fullWidth
						isInvalid={touched.line1 && !!errors.line1}
						label={
							markRequired
								? `${t('general.address.line1')} *`
								: t('general.address.line1')
						}
					>
						<EuiFieldText
							id="line1"
							isInvalid={touched.line1 && !!errors.line1}
							name="line1"
							onBlur={(e) => {
								handleBlur(e);
								const val = (e.target.value || '').replace(
									/\s+/gi,
									' ',
								);
								setFieldValue('line1', val.trim());
							}}
							onChange={handleChange}
							readOnly={readOnly}
							value={values.line1}
						/>
					</EuiFormRow>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
};

AddressForm.propTypes = {
	formikBag: PropTypes.shape({
		errors: PropTypes.object,
		handleBlur: PropTypes.func,
		handleChange: PropTypes.func,
		setFieldValue: PropTypes.func,
		setFieldTouched: PropTypes.func,
		touched: PropTypes.object,
		values: PropTypes.object,
	}).isRequired,
	fullWidth: PropTypes.bool,
	markRequired: PropTypes.bool,
	readOnly: PropTypes.bool,
	showCity: PropTypes.bool,
};

AddressForm.defaultProps = {
	fullWidth: false,
	markRequired: false,
	readOnly: false,
	showCity: true,
};

export default AddressForm;
