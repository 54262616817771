import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilesSlider from 'components/FilesSlider';
import {
	EuiButtonIcon,
	EuiDescriptionList,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFlyout,
	EuiFlyoutBody,
	EuiFlyoutHeader,
	EuiSpacer,
	EuiText,
	EuiIcon,
} from '@elastic/eui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ROLE_PATH } from 'constants/protect';
import { DOCTOR, SECRETARY } from 'components/roles';
import Table from 'components/Table';
import { formatDate } from 'utils/helpers';
import RxStatus from './components/RxStatus';
import SendToPatientButton from './components/SendToPatientButton';

const ViewPrescription = ({ data, onClose, visible }) => {
	const { t } = useTranslation();
	const { role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
	}));
	const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});

	if (!visible) return null;

	const { attachments } = data;

	const toggleDetails = (item) => {
		const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
		if (itemIdToExpandedRowMapValues[item.id]) {
			delete itemIdToExpandedRowMapValues[item.id];
		} else {
			itemIdToExpandedRowMapValues[item.id] = (
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiDescriptionList
							compressed
							listItems={[
								{
									title: 'Generic Name',
									description: item.generic ?? '',
								},
								{
									title: 'Brand',
									description: item.brand ?? '',
								},
								{
									title: 'Formulation',
									description: item.formulation ?? '',
								},
							]}
							type="column"
						/>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiDescriptionList
							compressed
							listItems={[
								{
									title: 'Prescribed Quantity',
									description: item.quantity ?? '',
								},
								{
									title: 'Remaining Quantity',
									description: item.currentQty ?? '',
								},
								{
									title: 'Sig',
									description: item.sig ?? '',
								},
							]}
							type="column"
						/>
					</EuiFlexItem>
				</EuiFlexGroup>
			);
		}
		setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
	};

	const descriptionList = [
		{
			title: 'Patient',
			description: `${data.patient.firstName} ${data.patient.lastName}`,
		},
		{
			title: t('general.date'),
			description: formatDate(data.createdAt),
		},
		{
			title: t('general.status'),
			description: (
				<RxStatus
					assigned={!!data.branch}
					datetime={data.createdAt}
					role={role}
					status={data.status}
				/>
			),
		},
		{
			title: t('general.type'),
			description: data.type.toUpperCase(),
		},
	];

	if (role !== DOCTOR.key) {
		descriptionList.push({
			title: t('general.doctor'),
			description: data.doctor
				? `${data.doctor.firstName} ${data.doctor.lastName}`
				: 'Patient Initiated',
		});
	}

	if (role === DOCTOR.key && role !== SECRETARY.key) {
		descriptionList.push({
			title: `Sent To Patient`,
			description: (
				<>
					<EuiText>
						<EuiIcon
							color={!data.sentToPatient ? 'danger' : 'green'}
							type={!data.sentToPatient ? 'cross' : 'check'}
						/>
						<SendToPatientButton
							rxId={data.id}
							sent={data.sentToPatient}
							size={16}
						/>
					</EuiText>
				</>
			),
		});
	}

	// eslint-disable-next-line react/prop-types
	if (data.patientNotes && data.patientNotes.length > 0) {
		descriptionList.push({
			title: `Note to Pharmacist`,
			// eslint-disable-next-line react/prop-types
			description: data.patientNotes,
		});
	}

	const columns = [
		{
			field: 'generic',
			name: 'Generic Name',
			type: 'string',
			width: '40%',
		},
		{
			field: 'brand',
			name: 'Brand',
			width: '30%',
		},
		{
			field: 'formulation',
			name: 'Formulation',
			width: '20%',
		},
		{
			align: 'right',
			isExpander: true,
			render: (item) => (
				<EuiButtonIcon
					aria-label={
						itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'
					}
					iconType={
						itemIdToExpandedRowMap[item.id]
							? 'arrowUp'
							: 'arrowDown'
					}
					onClick={() => toggleDetails(item)}
				/>
			),
		},
	];

	return (
		<>
			<EuiFlyout
				closeButtonAriaLabel="Close Add Order"
				onClose={() => {
					onClose();
					// resetForm({ values: initialValues });
				}}
				size="m"
				// style={{
				// 	zIndex: '1051 !important',
				// 	position: 'relative'
				// }}
			>
				<EuiFlyoutHeader hasBorder>
					<EuiText>
						<h3>{t('prescriptions.code', data)}</h3>
					</EuiText>
				</EuiFlyoutHeader>
				<EuiFlyoutBody>
					<EuiDescriptionList
						compressed
						listItems={descriptionList}
						type="column"
					/>
					<EuiSpacer />
					<FilesSlider
						data={attachments}
						name="attachments"
						readOnly
					/>
					<EuiSpacer />
					<Table
						columns={columns}
						isExpandable
						itemId="id"
						itemIdToExpandedRowMap={itemIdToExpandedRowMap}
						items={data?.prescriptionItems}
					/>
				</EuiFlyoutBody>
			</EuiFlyout>
		</>
	);
};

ViewPrescription.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string,
		createdAt: PropTypes.string,
		receivedDate: PropTypes.string,
		clinic: PropTypes.shape({
			name: PropTypes.string,
		}),
		doctor: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		attachments: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string,
				mime: PropTypes.string,
				name: PropTypes.string,
			}),
		),
		status: PropTypes.string,
		type: PropTypes.string,
		patient: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		prescriptionItems: PropTypes.arrayOf(
			PropTypes.shape({
				generic: PropTypes.string,
				brand: PropTypes.string,
			}),
		),
		vendor: PropTypes.shape({
			name: PropTypes.string,
		}),
		branch: PropTypes.shape({
			name: PropTypes.string,
		}),
		preferredBranch: PropTypes.shape({
			name: PropTypes.string,
		}),
		sentToPatient: PropTypes.bool,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	visible: PropTypes.bool,
};

ViewPrescription.defaultProps = {
	visible: false,
};

export default ViewPrescription;
