import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import numeral from 'numeral';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	EuiText,
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiSuperSelect,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiFieldNumber,
	EuiDescriptionList,
	EuiLink,
	EuiLoadingSpinner,
	EuiShowFor,
	EuiFlexGrid,
	EuiFieldText,
	EuiLoadingContent,
	EuiSelect,
	EuiRadioGroup,
	EuiIcon,
	EuiTabbedContent,
	EuiPageSection,
} from '@elastic/eui';
import { get, isEqual, isEmpty } from 'lodash';
import { FaPhoneSquare } from 'react-icons/fa';
import { CALL_CENTER, DOCTOR, PHARMACIST, SECRETARY } from 'components/roles';
import Page from 'components/Page';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import DropdownOptWSub from 'components/DropdownOptWSub';
import ContactDetails from 'modules/_global/ContactDetails';
import {
	CANCELLED,
	COMPLETED,
	PENDING_PAYMENT,
	// READY_FOR_PICKUP,
	READY_FOR_SHIPPING,
} from 'constants/order.statuses';
import { currencyFormat, getErrorMessage } from 'utils/helpers';
import FilesSlider from 'components/FilesSlider';
import BranchDropdown from 'components/forms/BranchDropdown';
import { MERCURY } from 'constants/vendor.names';
import OrderStatus from './components/OrderStatus';
import LineItemModal from './components/LineItemModal';
import { addToast } from '../toasts/toasts.actions';
import {
	getOrder,
	mapFormData,
	orderModel,
	confirmOrder,
	updateOrder,
	updateOrderStatus,
	updateOrderPaymentStatus,
	getGrabOrder,
} from './orders.fetch';
import PrintOrder from './PrintOrder';
import OrderStatusDropdown from './components/OrderStatusDropdown';
import ChatBox from '../comments/ChatBox';

// GRAB API
import {
	createDeliveryRequest,
	getDeliveryStatus,
	updateGrabEntry,
} from '../grab/grab';
import { getContactDetailsByIds } from '../_global/global.fetch';

const OrderSchema = Yup.object().shape({
	discountValue: Yup.number()
		.default(0)
		.min(0, 'Discount must be greater than or equal to 0')
		.when('discountType', {
			is: (value) => value && value === 'percent',
			then: Yup.number().max(100, 'Percent discount value is 0-100'),
		}),
	amount: Yup.number().test('is-decimal', 'Invalid Amount Input', (value) =>
		`${value}`.match(/^[0-9]\d*(\.\d+)?$/),
	),
});

const Order = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { orderId } = useParams();
	const dispatch = useDispatch();
	const { auth, vendor } = useSelector((state) => ({
		vendor: state.auth.user.vendor,
		auth: state.auth,
	}));
	const queryClient = useQueryClient();

	const [selected, setSelected] = useState([]);

	const [hasChanges, setHasChanges] = useState(false);
	const [newStatus, setNewStatus] = useState('');
	const [newStatusModal, setNewStatusModal] = useState(false);

	// For Payment Status and corresponding modal
	const [newPaymentStatus, setNewPaymentStatus] = useState('');
	const [newPaymentStatusModal, setNewPaymentStatusModal] = useState(false);

	const [selectedItem, setSelectedItem] = useState(null);
	const [isLineModalVisible, setIsLineModalVisible] = useState(false);
	const [isConfirmDeleteModalVisisble, setIsConfirmDeleteModalVisible] =
		useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);

	const priceEnabled = get(auth, 'user.vendor.priceEnabled');
	const role = get(auth, 'user.role.type');

	const onError = (err) => {
		const errorMessage = getErrorMessage(err);
		dispatch(addToast('Error', errorMessage, 'danger', 'help'));
	};

	const {
		data: order,
		isFetching,
		refetch,
	} = useQuery([`order-${orderId}`, orderId], getOrder, {
		placeholderData: {},
		onError: (err) => {
			onError(err);
			if (!order.id) {
				history.push('/orders');
			}
		},
	});

	// query for obtaining grab order
	const {
		data: grabOrder,

		// isFetching: isFetchingGrab,
		// refetch: refetchGrab,
	} = useQuery([`grab-${order.orderId}`, orderId], getGrabOrder, {
		placeholderData: {},
		onError: (err) => {
			onError(err);
		},
		enabled:
			order.status === 'readyForShipping' || order.status === 'complete',
	});

	// function for getting delivery status
	const getGrabStatus = async (grabOrderStat) => {
		// get delivery status
		const newGrabOrder = await getDeliveryStatus(grabOrderStat.deliveryId);
		let payload = {
			deliveryId: newGrabOrder.deliveryID,
			status: newGrabOrder.status,
			//   origin:order.branch.address.id,
			//   destination:order.patient.address.id,
			pickup: newGrabOrder.quote.estimatedTimeline.pickup,
			dropoff: newGrabOrder.quote.estimatedTimeline.dropoff,
			amount: newGrabOrder.quote.amount,
			//   order:order.id,
			//   branch:order.branch.id,
			//   patient:order.patient.id,
			trackingURL: newGrabOrder.trackingURL,
		};

		// console.log(newGrabOrder)

		if (newGrabOrder.status === 'PICKING_UP') {
			payload = {
				...payload,
				licensePlate: newGrabOrder.courier.vehicle.licensePlate,
				courierName: newGrabOrder.courier.name,
				courierPhone: newGrabOrder.courier.phone,
				allocateTime: newGrabOrder.timeline.allocate,
			};
		}

		if (newGrabOrder.status === 'IN_DELIVERY') {
			payload = {
				...payload,
				pickupTime: newGrabOrder.timeline.pickup,
			};
		}

		if (newGrabOrder.status === 'COMPLETED') {
			payload = {
				...payload,
				dropoffTime: newGrabOrder.timeline.dropoff,
			};
		}

		// update strapi grab entry
		updateGrabEntry(payload, grabOrder.id);
		queryClient.invalidateQueries([`grab-${order.orderId}`]);
	};

	useEffect(() => {
		// if grab order exists, update
		if (grabOrder && Object.keys(grabOrder).length > 0) {
			getGrabStatus(grabOrder);
		}
	});

	const onSuccess = () => {
		dispatch(addToast('Order Updated', null, 'success', 'check'));
		refetch();
	};

	const handleUpdatePaymentStatus = (e) => {
		// console.log(e.target.value)
		setNewPaymentStatus(e.target.value);
		setNewPaymentStatusModal(true);
	};

	const hideNewStatusModal = () => {
		setNewStatus('');
		setNewStatusModal(false);
	};

	const hideNewPaymentStatusModal = () => {
		setNewPaymentStatus('');
		setNewPaymentStatusModal(false);
	};

	const { isLoading, mutate } = useMutation(updateOrder, {
		onSuccess,
		onError,
	});

	const { isLoading: isLoadingConfirmingOrder, mutate: confirmMutate } =
		useMutation(confirmOrder, {
			onSuccess: () => {
				dispatch(addToast('Order confirmed', null, 'success', 'check'));
				refetch();
			},
			onError,
		});

	const { isLoading: isLoadingStatusUpdate, mutate: statusMutate } =
		useMutation(updateOrderStatus, {
			onSuccess: () => {
				onSuccess();
			},
			onError,
		});

	useEffect(() => {
		if (!isLoadingStatusUpdate) setNewStatusModal(false);
	}, [isLoadingStatusUpdate]);

	// mutate function for updating payment status
	const {
		isLoading: isLoadingPaymentStatusUpdate,
		mutate: paymentStatusMutate,
	} = useMutation(updateOrderPaymentStatus, {
		onSuccess: () => {
			onSuccess();
			hideNewPaymentStatusModal();
		},
		onError,
	});

	useEffect(() => {
		if (!isLoadingPaymentStatusUpdate) setNewPaymentStatusModal(false);
	}, [isLoadingPaymentStatusUpdate]);

	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			...orderModel,
			...mapFormData(order),
		},
		validationSchema: OrderSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			mutate(data);
		},
	});

	useEffect(() => {
		setHasChanges(isEqual(initialValues, values));
	}, [values]);

	const {
		address,
		amount,
		branch,
		cancellationReason,
		discountSubtotal,
		discountType,
		discountValue,
		lineItems,
		lineItemsSubtotal,
		paid,
		pickup,
		prescription,
		status,
		totalAmount,
		vatAmount,
	} = values;

	const disableForMecury =
		role === PHARMACIST.key && get(vendor, 'name') === MERCURY;

	const handleUpdateStatus = (updateStat) => {
		if (
			updateStat === 'pendingPayment' &&
			(amount === '' || amount === 0)
		) {
			const err = {
				response: {
					data: {
						error: 'Invalid Value',
						message: 'Total Amount Required',
					},
				},
			};
			onError(err);
			return;
		}
		setNewStatus(updateStat);
		setNewStatusModal(true);
	};

	// order is completed
	const isCompleted = status === 'completed';
	// order status is 'cancelled'
	const isCancelled = status === 'cancelled';

	useEffect(() => {
		async function computeValues() {
			if (lineItems && lineItems.length > -1) {
				const subtotal = lineItems.reduce(
					(a, b) => a + (b.price * b.quantity || 0),
					0,
				);
				const vat = 0.12;
				let discount = 0;
				let payment = 0;

				// eslint-disable-next-line default-case
				switch (discountType) {
					case 'percent':
						if (discountValue <= 100) {
							discount = subtotal * (discountValue / 100);
						}
						break;
					case 'fixed':
						if (discountValue <= subtotal) {
							discount = discountValue;
						}
						break;
				}

				if (!subtotal - discount < 0) {
					payment = subtotal - discount;
				} else {
					payment = subtotal;
				}

				setFieldValue('vatAmount', payment * vat);
				setFieldValue('discountSubtotal', discount);
				setFieldValue('lineItemsSubtotal', subtotal);
				setFieldValue('totalAmount', payment);
			}
		}
		computeValues();
	}, [discountValue, discountType, lineItems]);

	const addLineItem = (data) => {
		const updatedLineItems = [...lineItems, data];
		setFieldValue('lineItems', updatedLineItems);
	};

	const handleEditLineItemModal = (data) => {
		setSelectedItem(data);
		setIsLineModalVisible(true);
	};

	const updateLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		const itemsStart = lineItems.slice(0, index);
		const itemsEnd = lineItems.slice(index + 1);

		setFieldValue('lineItems', [...itemsStart, data, ...itemsEnd]);
	};

	const removeLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		const lineItemsStart = lineItems.slice(0, index);
		const lineItemsEnd = lineItems.slice(index + 1);
		setFieldValue('lineItems', [...lineItemsStart, ...lineItemsEnd]);
	};

	const updateStatus = async () => {
		const payload = {
			id: orderId,
			status: newStatus,
		};

		if (newStatus === CANCELLED.value && cancellationReason) {
			payload.cancellationReason = cancellationReason;
		}

		if (newStatus === PENDING_PAYMENT.value) {
			payload.amount = amount;
			payload.pickup = pickup;
		}

		// disable payment restriction for now
		// if (newStatus === READY_FOR_SHIPPING.value && !order.isPaid) {
		// 	dispatch(
		// 		addToast(
		// 			'Order is not yet paid. If it is already paid, please update payment status',
		// 			null,
		// 			'danger',
		// 		),
		// 	);
		// 	hideNewStatusModal();

		// 	return;
		// }

		// disable payment restriction for now
		// if (newStatus === READY_FOR_PICKUP.value && !order.isPaid) {
		// 	dispatch(
		// 		addToast(
		// 			'Order is not yet paid. If it is already paid, please update payment status',
		// 			null,
		// 			'danger',
		// 		),
		// 	);
		// 	hideNewStatusModal();

		// 	return;
		// }

		// when updating status to ready for shipping, request for grab driver
		if (newStatus === READY_FOR_SHIPPING.value) {
			const contactTypes = ['mobile', 'phone'];
			const { data } = await getContactDetailsByIds(
				order.patient.contactDetails,
				contactTypes,
			);
			createDeliveryRequest(order, data[0].value);
			dispatch(
				addToast('Grab Delivery Requested', null, 'success', 'check'),
			);
		}

		statusMutate(payload);
	};

	// function for updating payment status
	const updatePaymentStatus = async () => {
		const payload = {
			id: orderId,
			status: newPaymentStatus,
		};
		paymentStatusMutate(payload);
	};

	const handleConfirmOrder = () => {
		confirmMutate(orderId);
	};

	const handleConfirmDeleteLineItemModal = (data) => {
		setSelectedItem(data);
		setIsConfirmDeleteModalVisible(true);
	};

	const handleDeleteLineItem = () => {
		removeLineItem(selectedItem);
		setIsConfirmDeleteModalVisible(false);
		setSelectedItem(null);
	};

	const handleCloseDeleteLineItemModal = () => {
		setIsConfirmDeleteModalVisible(false);
		setSelectedItem(null);
	};

	const handleCancelChanges = () => {
		resetForm({ values: initialValues });
		setIsConfirmCancelChangesModalVisible(false);
	};

	const handleCancelChangesModal = () => {
		setIsConfirmCancelChangesModalVisible((isModalOpen) => isModalOpen);
	};

	let columns = [
		{ field: 'itemName', name: t('orders.itemName'), sortable: true },
		{
			field: 'brand',
			name: t('orders.brand'),
			sortable: true,
		},
		{
			field: 'description',
			name: t('orders.description'),
			sortable: true,
		},
		{
			field: 'quantity',
			name: t('orders.quantity'),
			sortable: true,
			dataType: 'number',
			width: '10%',
		},
	];

	if (priceEnabled) {
		columns = [
			...columns,
			{
				field: 'sku',
				name: t('orders.sku'),
				sortable: true,
				width: '10%',
			},
			{
				field: 'price',
				name: t('orders.price'),
				sortable: true,
				dataType: 'number',
				width: '10%',
				render: (price) => (
					<EuiText>{`P ${numeral(price).format('0,0.00')}`}</EuiText>
				),
			},
			{
				name: t('orders.subtotal'),
				// eslint-disable-next-line react/prop-types
				render: ({ price, quantity }) => {
					return (
						<EuiText>
							{`P ${numeral(quantity * price).format('0,0.00')}`}
						</EuiText>
					);
				},
				dataType: 'number',
				width: '10%',
			},
		];
	}

	if (!disableForMecury && status === 'open' && vendor.requireRxEncoding) {
		columns = [
			...columns,
			{
				width: '10%',
				actions: [
					{
						name: 'Edit',
						description: 'Edit',
						onClick: handleEditLineItemModal,
						icon: 'pencil',
						type: 'icon',
						color: 'primary',
						isPrimary: true,
					},
					{
						name: 'Delete',
						description: 'Delete',
						onClick: handleConfirmDeleteLineItemModal,
						icon: 'trash',
						type: 'icon',
						color: 'danger',
						isPrimary: true,
					},
				],
			},
		];
	}

	const discountOpts = [
		{
			value: 'percent',
			inputDisplay: t('orders.percent'),
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.percentSample')}
					title={t('orders.percent')}
				/>
			),
		},
		{
			value: 'fixed',
			inputDisplay: 'Fixed Amount',
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.amountSample')}
					title={t('orders.amount')}
				/>
			),
		},
	];

	const lineModal = isLineModalVisible ? (
		<LineItemModal
			addLineItem={addLineItem}
			onClose={() => {
				setIsLineModalVisible(false);
				setSelectedItem(null);
			}}
			selectedItem={selectedItem}
			updateLineItem={updateLineItem}
		/>
	) : null;

	const selection = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelected(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selected,
	};

	const disableButton = Object.keys(errors).length > 0;

	let orderDetails = [];
	const { deliveryAddress, patient } = order;

	const renderStatus = () => (
		<>
			<OrderStatusDropdown
				isPaid={order.isPaid}
				onChange={handleUpdateStatus}
				pickup={order.pickup}
				readOnly={
					(role !== CALL_CENTER.key || role !== PHARMACIST.key) &&
					status === COMPLETED.value
				}
				selected={
					prescription.status === 'cancelled'
						? CANCELLED.value
						: order.status
				}
			/>
			{status === CANCELLED.value && cancellationReason && (
				<span style={{ fontSize: 12 }}>
					Reason: {cancellationReason}
				</span>
			)}
		</>
	);

	const renderShippingDetails = () => {
		if (
			prescription.preferredPickupType === 'delivery' ||
			prescription.preferredPickupType === 'grab'
		) {
			let listItems = [];

			if (deliveryAddress) {
				const mainNumber = patient?.contactDetails?.filter(
					(m) => m.type === 'mobile',
				)?.[0];

				listItems = [
					{
						title: 'Name',
						description: (
							<>
								{deliveryAddress?.fullName ??
									`${patient?.firstName} ${patient?.lastName}`}
							</>
						),
					},
					{
						title: 'Contact Number',
						description: (
							<>
								{deliveryAddress?.mainNumber ??
									patient?.mainNumber ??
									mainNumber?.value ??
									''}
							</>
						),
					},
					{
						title: 'Address',
						description: !deliveryAddress?.fullAddress ? (
							<>
								{deliveryAddress?.address?.line1 ?? ''}{' '}
								{deliveryAddress?.address?.line2 ?? ''}
								{deliveryAddress?.address?.brgy ?? ''}{' '}
								{deliveryAddress?.address?.city ?? ''}
								{deliveryAddress?.address?.province ?? ''}{' '}
								{deliveryAddress?.address?.region ?? ''}
							</>
						) : (
							<>{deliveryAddress?.fullAddress}</>
						),
					},
				];
			} else {
				listItems = [
					{
						title: 'Name',
						description: (
							<>
								{patient.firstName} {patient.lastName}
							</>
						),
					},
					{
						title: 'Address',
						description: (
							<>
								{patient.address?.line1 ?? ''}{' '}
								{patient.address?.line2 ?? ''}
								{patient.address?.brgy ?? ''}{' '}
								{patient.address?.city ?? ''}
								{patient.address?.province ?? ''}{' '}
								{patient.address?.region ?? ''}
							</>
						),
					},
				];
			}

			return (
				<>
					<EuiText>
						<strong>Shipping Details</strong>
					</EuiText>
					<EuiPageSection>
						<EuiDescriptionList
							listItems={listItems}
							type="responsiveColumn"
						/>
					</EuiPageSection>
				</>
			);
		}

		return '';
	};

	const getColor = (type) => {
		switch (type) {
			case 'email':
				return '#00805e';

			case 'phone':
				return '#d76e10';

			case 'mobile':
				return '#c0e0ff';

			default:
				return '#d2d2d2';
		}
	};

	if (patient) {
		orderDetails = [
			{
				title: 'Patient',
				description: patient
					? `${patient.firstName} ${patient.lastName}`
					: '',
			},
			{
				title: 'Contact Details',
				description:
					patient && !isEmpty(patient.contactDetails) ? (
						<ContactDetails ids={patient.contactDetails} plain />
					) : (
						<>
							<div style={{ verticalAlign: 'middle' }}>
								<EuiShowFor sizes={['xl', 'l', 'm']}>
									<EuiLink
										color={getColor('phone')}
										href="tel:"
									>
										{patient.mainNumber}
									</EuiLink>
									<EuiButton
										href="tel:"
										size="s"
										style={{ marginLeft: 5 }}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<FaPhoneSquare
												color="#117aac"
												style={{ marginRight: 5 }}
											/>
											Call Now
										</div>
									</EuiButton>
								</EuiShowFor>
								<EuiShowFor sizes={['xs', 's']}>
									<EuiButtonEmpty href="tel:" size="xs">
										<FaPhoneSquare
											color="#117aac"
											style={{ marginRight: 5 }}
										/>
									</EuiButtonEmpty>
								</EuiShowFor>
								<div>
									<EuiLink
										color={getColor('email')}
										href="mailto:"
									>
										{patient.email}
									</EuiLink>
								</div>
							</div>
						</>
					),
			},
			{
				title: 'Pickup Type',
				description:
					prescription.preferredPickupType?.charAt(0).toUpperCase() +
					prescription.preferredPickupType?.slice(1),
			},
			{
				title: 'Order Status',
				description: renderStatus(),
			},
			{
				title: 'Prescription',
				description: prescription ? (
					<Link to={`/prescriptions/${prescription.id}`}>
						<EuiLink>
							{(prescription && prescription.code) || ''}
						</EuiLink>
					</Link>
				) : (
					<EuiLoadingSpinner />
				),
			},
		];
	}

	const onBranchSelect = (selectedOption) => {
		setFieldTouched('branch', true);
		if (selectedOption) {
			return setFieldValue('branch', selectedOption.value);
		}

		return setFieldValue('branch', null);
	};

	const getDescription = () => {
		if (isCompleted) {
			return currencyFormat(amount);
		}

		return (
			<>
				<EuiFieldNumber
					disabled={disableForMecury}
					fullWidth
					id="amount"
					isInvalid={touched.amount && !!errors.amount}
					min={0}
					name="amount"
					onChange={handleChange}
					step={0.01}
					value={amount}
				/>
				<EuiSpacer size="s" />
				{errors.amount && !!errors.amount && (
					<span
						style={{
							fontSize: 10,
							color: 'red',
							fontWeight: 'normal',
						}}
					>
						{errors.amount}
					</span>
				)}
			</>
		);
	};

	const getGrabTitle = (stat) => {
		if (stat === 'PICKING_UP') {
			return 'Allocate Time';
		}

		if (stat === 'IN_DELIVERY') {
			return 'Pickup Time';
		}

		return 'Dropoff Time';
	};

	const getGrabDescription = (stat) => {
		if (stat === 'PICKING_UP') {
			return grabOrder.allocateTime;
		}

		if (stat === 'IN_DELIVERY') {
			return grabOrder.status;
		}

		return grabOrder.dropoffTime;
	};

	const renderFilesSlider = (data, name, label) => {
		if (data && data.length) {
			return (
				<>
					<EuiText>
						<strong>{label}</strong>
					</EuiText>
					<EuiSpacer />
					<FilesSlider
						data={data}
						name={name}
						// onChange={setFieldValue}
					/>
				</>
			);
		}
		return null;
	};

	if (role === CALL_CENTER.key) {
		orderDetails.push({
			title: 'Branch',
			description: (
				<BranchDropdown
					cityId={get(values, 'patient.address.cityId', null)}
					onChange={onBranchSelect}
					value={get(values, 'branch', null)}
					vendor={get(vendor, 'id', null)}
				/>
			),
		});
	}

	if (pickup && branch) {
		orderDetails.push({
			title: 'Pick Up',
			description: branch.name,
		});
	}

	if (vendor.totalOnly) {
		orderDetails.push({
			title: 'Total Amount',
			description: getDescription(),
		});
	}

	// show payment status and proof of payment if status is pending payment or ready for shipping
	if (
		// hide payment status for now
		[].length > 0 &&
		(status === 'pendingPayment' || status === 'readyForShipping')
	) {
		const paymentStatusOptions = [
			{ value: false, text: 'Pending' },
			{ value: true, text: 'Paid' },
		];
		orderDetails.push({
			title: 'Payment Status',
			description: (
				<>
					<EuiSelect
						// eslint-disable-next-line react/jsx-props-no-spreading
						// data-testid="order.status"
						id="paid"
						name="paid"
						onChange={(e) => handleUpdatePaymentStatus(e)}
						options={paymentStatusOptions}
						value={paid}
					/>
				</>
			),
		});

		orderDetails.push({
			title: 'Proof of Payment',
			description: (
				<>
					<FilesSlider
						data={order.proofOfPayment}
						// deleteEnabled
						// deletedData={values.deletedFiles}
						// isLoading={isLoadingAttachments}
						name="additionalAttachments"
						// onChange={setFieldValue}
					/>
				</>
			),
		});
	}

	// if ready for shipping and grab order exists, show grab delivery details
	if (status === 'readyForShipping' && grabOrder) {
		// console.log(grabOrder)
		orderDetails.push({
			title: 'GRAB Delivery Status',
			description: grabOrder.status,
		});

		if (
			grabOrder.status === 'PICKING_UP' ||
			grabOrder.status === 'IN_DELIVERY' ||
			grabOrder.status === 'COMPLETED'
		) {
			orderDetails.push({
				title: 'Courier Name',
				description: grabOrder.courierName,
			});

			orderDetails.push({
				title: 'Courier Phone',
				description: grabOrder.courierPhone,
			});

			orderDetails.push({
				title: 'License Plate',
				description: grabOrder.licensePlate,
			});

			orderDetails.push({
				title: getGrabTitle(grabOrder.status),
				description: getGrabDescription(grabOrder.status),
			});
		}

		orderDetails.push({
			title: 'Tracking Link',
			description: (
				<a href={grabOrder.trackingURL}>
					{grabOrder.trackingURL ?? 'No link provided'}
				</a>
			),
		});
	}

	const tabs = [
		{
			id: 'info',
			name: 'Info',
			content: (
				<>
					<EuiSpacer />
					{patient && (
						<EuiDescriptionList
							listItems={orderDetails}
							type="responsiveColumn"
						/>
					)}
					<EuiSpacer />
					{renderShippingDetails()}
					{renderFilesSlider(
						order?.prescription?.attachments,
						'attachments',
						'Attachments',
					)}
					{renderFilesSlider(
						order?.prescription?.additionalAttachments,
						'additionalAttachments',
						'Additional Attachments',
					)}

					{role === CALL_CENTER.key && (
						<>
							<EuiSpacer />
							<EuiFlexGroup>
								<EuiFlexItem grow={false}>
									<EuiButton
										disabled={
											values.confirmed ||
											isLoadingConfirmingOrder ||
											isFetching
										}
										isLoading={isLoadingConfirmingOrder}
										onClick={handleConfirmOrder}
									>
										<EuiText>
											{!values.confirmed
												? 'Confirm Order'
												: 'Confirmed'}{' '}
											<EuiIcon
												color={`${
													!values.confirmed
														? 'red'
														: 'green'
												}`}
												type={`${
													!values.confirmed
														? ''
														: 'check'
												}`}
											/>
										</EuiText>
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</>
					)}
				</>
			),
		},
		{
			id: 'order-chatbox',
			name: 'Chat Box',
			content: (
				<ChatBox
					params={{
						prescription: get(prescription, 'id', null),
						// order: order.id,
					}}
				/>
			),
		},
	];

	let displayAddress = '';

	if (address) {
		if (address.localAddress) displayAddress = address.localAddress;

		if (!address.localAddress) {
			displayAddress = `${get(address, 'line1', '')}`;
			displayAddress += ` ${get(address, 'line2', '')}`;
			displayAddress += `, ${get(address, 'city', '')}`;
		}
	}

	let title = t('orders.orderTitle', {
		id: order.orderId,
	});

	if (order.packingCode) {
		title += ` - ${order.packingCode}`;
	}
	if (!order.id && isFetching) {
		return <EuiLoadingContent lines={1} />;
	}

	const deliveryModes = [
		{ value: false, text: 'GRAB Delivery' },
		// { value: true, text: "Pickup" },
	];

	return (
		<>
			<Page
				headerRight={
					<>
						<EuiShowFor sizes={['xl', 'l', 'm']}>
							<EuiFlexGroup direction="row" gutterSize="m">
								<EuiFlexItem>
									{order && patient && (
										<PrintOrder
											address={displayAddress}
											contactDetails={
												patient.contactDetails
											}
											disabled={disableForMecury}
											lineItems={lineItems}
											name={`${patient.firstName} ${patient.lastName}`}
											orderId={order.orderId}
											prescriptionCode={
												prescription &&
												prescription.code
											}
										/>
									)}
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiButtonEmpty
										color="danger"
										disabled={
											isLoading ||
											hasChanges ||
											disableForMecury
										}
										iconType="crossInACircleFilled"
										onClick={handleCancelChangesModal}
									>
										{t('general.cancel')}
									</EuiButtonEmpty>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiButton
										disabled={
											isLoading ||
											hasChanges ||
											disableButton ||
											disableForMecury
										}
										fill
										iconType="checkInCircleFilled"
										isLoading={isLoading}
										onClick={handleSubmit}
									>
										Save
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiShowFor>
						<EuiShowFor sizes={['s', 'xs']}>
							<EuiFlexGrid columns={3} responsive={false}>
								<EuiFlexItem style={{ flexGrow: 1 }}>
									{order && patient && (
										<PrintOrder
											address={displayAddress}
											contactDetails={
												patient.contactDetails
											}
											disabled={disableForMecury}
											lineItems={lineItems}
											name={`${patient.firstName} ${patient.lastName}`}
											orderId={order.orderId}
											packingCode={order.packingCode}
											prescriptionCode={
												prescription &&
												prescription.code
											}
											size="s"
										/>
									)}
								</EuiFlexItem>
								<EuiFlexItem style={{ flexGrow: 1 }}>
									<EuiButtonEmpty
										color="danger"
										disabled={
											isLoading ||
											hasChanges ||
											disableForMecury
										}
										onClick={handleCancelChangesModal}
										size="xs"
									>
										{t('general.cancel')}
									</EuiButtonEmpty>
								</EuiFlexItem>
								<EuiFlexItem style={{ flexGrow: 1 }}>
									<EuiButtonEmpty
										disabled={
											isLoading ||
											hasChanges ||
											disableButton ||
											disableForMecury
										}
										isLoading={isLoading}
										onClick={handleSubmit}
										size="xs"
									>
										Save
									</EuiButtonEmpty>
								</EuiFlexItem>
							</EuiFlexGrid>
						</EuiShowFor>
					</>
				}
				sidebar={
					<EuiTabbedContent
						autoFocus="selected"
						expand
						initialSelectedTab={tabs[0]}
						tabs={tabs}
					/>
				}
				stickyHeader
				title={title}
			>
				<EuiTitle size="xs">
					<h3>{t('orders.orderSummary')}</h3>
				</EuiTitle>
				<EuiHorizontalRule margin="xs" />
				<EuiFlexGroup direction="row" justifyContent="spaceBetween">
					<EuiFlexItem style={{ maxWidth: 750 }}>
						<EuiForm>
							<EuiFlexGroup>
								{priceEnabled && (
									<EuiFlexItem>
										<EuiFormRow
											label={t('orders.discountType')}
										>
											<EuiSuperSelect
												id="discountType"
												name="discountType"
												onChange={(value) =>
													setFieldValue(
														'discountType',
														value,
													)
												}
												options={discountOpts}
												valueOfSelected={discountType}
											/>
										</EuiFormRow>
										<EuiFormRow
											error={errors.discountValue}
											isInvalid={!!errors.discountValue}
											label={t('orders.discountValue')}
										>
											<EuiFieldNumber
												id="discountValue"
												isInvalid={
													touched.discountValue &&
													!!errors.discountValue
												}
												max={
													discountType === 'percent'
														? 100
														: lineItemsSubtotal
												}
												min={0}
												name="discountValue"
												onChange={handleChange}
												value={discountValue}
											/>
										</EuiFormRow>
									</EuiFlexItem>
								)}
							</EuiFlexGroup>
						</EuiForm>
					</EuiFlexItem>
					{priceEnabled && (
						<EuiFlexItem style={{ maxWidth: 300 }}>
							<EuiDescriptionList
								listItems={[
									{
										title: t('orders.subtotal'),
										description: `P ${numeral(
											lineItemsSubtotal,
										).format('0,0.00')}`,
									},
									{
										title: t('orders.discount'),
										description: `P ${numeral(
											discountSubtotal,
										).format('0,0.00')}`,
									},
									{
										title: t('orders.vat'),
										description: `P ${numeral(
											vatAmount,
										).format('0,0.00')}`,
									},
									{
										title: t('orders.total'),
										description: `P ${numeral(
											totalAmount,
										).format('0,0.00')}`,
									},
								]}
								type="responsiveColumn"
							/>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
				{priceEnabled && <EuiHorizontalRule margin="xs" />}
				<EuiSpacer size="m" />

				<EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
					{selected.length ? (
						<EuiFlexItem grow={false}>
							<EuiButton
								color="danger"
								fill={false}
								iconType="trash"
								size="s"
							>
								Delete
							</EuiButton>
						</EuiFlexItem>
					) : null}
					<EuiFlexItem>
						<EuiTitle size="xs">
							<h1>{t('orders.lineItems')}</h1>
						</EuiTitle>
					</EuiFlexItem>
					{vendor.requireRxEncoding && (
						<EuiFlexItem>
							<EuiFlexGroup
								gutterSize="s"
								justifyContent="flexEnd"
							>
								<EuiFlexItem grow={false}>
									<EuiButton
										disabled={
											isCompleted ||
											isCancelled ||
											disableForMecury
										}
										fullWidth={false}
										iconType="plusInCircle"
										onClick={() =>
											setIsLineModalVisible(true)
										}
										size="s"
									>
										{t('orders.addLineItem')}
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
				<EuiHorizontalRule margin="xs" />
				<Table
					columns={columns}
					isExpandable={vendor.requireRxEncoding}
					isSelectable={vendor.requireRxEncoding && !disableForMecury}
					itemId="id"
					items={lineItems}
					selection={
						vendor.requireRxEncoding && !disableForMecury
							? selection
							: null
					}
				/>
				{lineModal}
			</Page>
			<ConfirmationModal
				isVisible={isConfirmDeleteModalVisisble}
				message="Are you sure you want to do this?"
				onClose={handleCloseDeleteLineItemModal}
				onConfirm={handleDeleteLineItem}
				title={t('deleteLineItem.title')}
			/>
			<ConfirmationModal
				isVisible={isConfirmCancelChangesModalVisible}
				message={t('general.cancelChanges')}
				onClose={handleCancelChangesModal}
				onConfirm={handleCancelChanges}
				title={t('general.confirm')}
			/>
			<ConfirmationModal
				confirmButtonDisabled={isLoadingStatusUpdate}
				// initialFocus="[name=cancellationReason]"
				isVisible={newStatusModal}
				message={
					<>
						<EuiSpacer size="xs" />
						<EuiFlexGroup direction="column">
							<EuiFlexItem>
								<span style={{ fontSize: 20 }}>
									New Status:{' '}
									<OrderStatus status={newStatus} />{' '}
									{isLoadingStatusUpdate && (
										<EuiLoadingSpinner size="m" />
									)}
								</span>
							</EuiFlexItem>
							{newStatus === 'pendingPayment' && (
								<EuiFlexItem>
									{/* <EuiText>Enter Total Amount</EuiText>
									<EuiFieldText
										id="amount"
										name="amount"
										// isInvalid={
										// 	errors.cancellationReason
										// }
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder="1000"
										value={amount}
									/>
									<EuiSpacer size="s" /> */}
									{!pickup && (
										<>
											<EuiText>Delivery Mode</EuiText>
											<EuiSelect
												// eslint-disable-next-line react/jsx-props-no-spreading
												// data-testid="order.status"
												// id="order.status"
												// name="order.status"
												// onChange={onChange}
												id="pickup"
												name="pickup"
												onChange={handleChange}
												options={deliveryModes}
												value={pickup}
											/>
										</>
									)}
								</EuiFlexItem>
							)}
							{newStatus === 'readyForShipping' && (
								<EuiFlexItem>
									<EuiText>Recipient Name</EuiText>
									<EuiText>
										{patient.firstName} {patient.lastName}
									</EuiText>
									{/* <EuiFieldText
										id="totalAmount"
										name="totalAmount"
										// isInvalid={
										// 	errors.cancellationReason
										// }
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder="1000"
										value={totalAmount}
									/> */}
									<EuiSpacer size="s" />
									<EuiText>Recipient Contact</EuiText>
									<ContactDetails
										hideEmails={
											role !== DOCTOR.key ||
											role === SECRETARY.key
										}
										ids={patient.contactDetails}
									/>
									{/* <EuiSelect
										// eslint-disable-next-line react/jsx-props-no-spreading
										// data-testid="order.status"
										// id="order.status"
										// name="order.status"
										// onChange={onChange}
										id="pickup"
										name="pickup"
										onChange={handleChange}
										value={pickup}
										options={deliveryModes}
									/> */}
									<EuiSpacer size="s" />
									<EuiText>Recipient Note</EuiText>
									<EuiFieldText
									// id="address.fullAddress"
									// name="address.fullAddress"
									// onBlur={handleBlur}
									// onChange={handleChange}
									// // placeholder="1000"
									// value={address.fullAddress}
									/>

									<EuiSpacer size="s" />
									<EuiText>Recipient Address</EuiText>
									<EuiFieldText
										id="address.fullAddress"
										name="address.fullAddress"
										onBlur={handleBlur}
										onChange={handleChange}
										// placeholder="1000"
										value={address.fullAddress}
									/>

									<EuiSpacer size="s" />
									<EuiText>
										Sender&apos;s / Your Note to the Driver
									</EuiText>
									<EuiFieldText
									// id="address.fullAddress"
									// name="address.fullAddress"
									// onBlur={handleBlur}
									// onChange={handleChange}
									// // placeholder="1000"
									// value={address.fullAddress}
									/>
								</EuiFlexItem>
							)}
							{newStatus === 'cancelled' && (
								<EuiFlexItem>
									<EuiText>Reason for Cancellation</EuiText>
									<EuiSpacer size="s" />
									<EuiRadioGroup
										idSelected={values.cancellationReason}
										// legend={{
										// 	children: (
										// 		<span>Select reason</span>
										// 	),
										// }}
										name="cancellationReason"
										onChange={(id) => {
											setFieldValue(
												'cancellationReason',
												id,
											);
										}}
										options={[
											{
												id: 'Store is busy',
												label: 'Store is busy',
											},
											{
												id: 'Out of stock brand',
												label: 'Out of stock brand',
											},
											{
												id: 'Out of stock category',
												label: 'Out of stock category',
											},
										]}
									/>
									<EuiSpacer size="s" />
									<EuiFieldText
										id="cancellationReason"
										name="cancellationReason"
										// isInvalid={
										// 	errors.cancellationReason
										// }
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder="Others"
										value={cancellationReason}
									/>
								</EuiFlexItem>
							)}
						</EuiFlexGroup>
					</>
				}
				onClose={hideNewStatusModal}
				onConfirm={updateStatus}
				title="Change Status"
			/>
			<ConfirmationModal
				isVisible={newPaymentStatusModal}
				message="Would you like to update payment status?"
				onClose={hideNewPaymentStatusModal}
				onConfirm={updatePaymentStatus}
				title="Change Payment Status"
			/>
		</>
	);
};

export default Order;
