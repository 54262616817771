import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
	EuiForm,
	EuiDescriptionList,
	EuiFormRow,
	EuiFormLabel,
	EuiButton,
	EuiButtonEmpty,
	EuiLoadingSpinner,
	EuiCallOut,
	EuiButtonIcon,
	EuiRadioGroup,
	EuiPageSection,
} from '@elastic/eui';
import { MdOpenInNew } from 'react-icons/md';
import { isEmpty, keys, pickBy, identity } from 'lodash';
import AddressForm from 'components/AddressForm';
import { buildLocalAddress, getInvalid, getErrorMessage } from 'utils/helpers';
import ConfirmationModal from 'components/ConfirmationModal';
import BookingModal from 'components/BookingModal';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
// import GCash from 'assets/images/gcash.png';
// import Paymaya from 'assets/images/paymaya.png';
// import Card from 'assets/images/visa-mastercard.png';
import PropTypes from 'prop-types';
import GrabModal from './GrabModal';
import PaymentModal from './PaymentModal';
import { addToast } from '../../toasts/toasts.actions';

import {
	mapValidateRxData,
	validateRxModel,
	putPaymentRx,
	createCheckout,
} from '../validate-prescription.fetch';

import { getFailedGrabOrders } from '../../orders/orders.fetch';

// GRAB API
import {
	cancelDelivery,
	createDeliveryRequest,
	getDeliveryStatus,
	getGrabOrder,
	getQuote,
	updateGrabEntry,
} from '../../grab/grab';

const RxPaymentFormValidationSchema = yup.object().shape({
	proofOfPayment: yup
		.array()
		.of(yup.string())
		.max(10, 'You are only allowed to upload a maximum of 10 files'),
});

// eslint-disable-next-line no-unused-vars
const RxPaymentForm = ({ handleChanges, hasChanges, onClose, rx, token }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const onError = (err) => {
		const errorMessage = getErrorMessage(err);
		dispatch(addToast('Error', errorMessage, 'danger', 'help'));
	};

	const [grabQuote, setGrabQuote] = useState(null);
	const [grabQuoteCOD, setGrabQuoteCOD] = useState(null);
	const [grabQuoteMagpie, setGrabQuoteMagpie] = useState(null);
	const [totalAmount, setTotalAmount] = useState(null);
	const [convenienceFee, setConvenienceFee] = useState(null);
	const [isLoading] = useState(false);

	const [paymentOpen, setPaymentOpen] = useState(false);
	const [editableAddress, setEditableAddress] = useState(false);
	const [isCancelModalVisible, setCancelModalVisible] = useState(false);
	const [isCancelTransactionModalVisible, setCancelTransactionModalVisible] =
		useState(false);
	const [isConfirmationModalVisible, setConfirModalVisible] = useState(false);
	const [isBookingModalVisible, setBookingModalVisible] = useState(false);
	const [isGrabModalVisible, setGrabModalVisible] = useState(false);
	const [seconds, setSeconds] = useState(60);
	const [isActive, setIsActive] = useState(false);
	const [grabModalTitle, setGrabModalTitle] = useState('');
	const [grabModalContent, setGrabModalContent] = useState('');
	const [riderBooked, setRiderBooked] = useState(false);
	const [deliveryStatus, setDeliveryStatus] = useState(null);
	const [isCreatingCheckout, setCreatingCheckout] = useState(false);

	const { isLoading: isSubmitting, mutate } = useMutation(putPaymentRx, {
		onSuccess: () => {
			handleChanges(false);
			dispatch(
				addToast('Order updated', 'Changes Saved', 'success', 'check'),
			);
		},
		onError: () => {
			dispatch(
				addToast(
					'Error: Something went wrong',
					'No Changes Saved',
					'danger',
					'help',
				),
			);
		},
	});

	const formikBag = useFormik({
		initialValues: {
			...validateRxModel,
			...mapValidateRxData(rx),
		},
		validateOnBlur: true,
		validationSchema: RxPaymentFormValidationSchema,
		enableReinitialize: true,
		onSubmit: async (data) => {
			mutate({
				...data,
				// additionalAttachments,
				token,
			});
		},
	});

	const {
		errors,
		// handleSubmit,
		// setFieldTouched,
		setFieldValue,
		// setTouched,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		if (!values.order.isPaid && values.order.paymentMethod === '')
			values.order.paymentMethod = values.paymentMethod;
	}, [values.paymentMethod]);

	useEffect(() => {
		if (!values.order.isPaid && !values.order.paymentMethod)
			setFieldValue('order.paymentMethod', 'magpie');
	}, []);

	const {
		data: failedGrabOrders,
		refetch: refetchFailed,
		// isFetching: isFetchingGrab,
	} = useQuery(
		[`getFailedOrders-${values.order.orderId}`, `${values.order.id}`],
		getFailedGrabOrders,
		{
			placeholderData: {},
			onError: (err) => {
				dispatch(
					addToast(
						'Error: Could not retrieve grab order history',
						err.message,
						'danger',
						'help',
					),
				);
			},
			enabled:
				// !deliveryStatus &&
				values.order.status === 'readyForShipping' ||
				values.order.status === 'readyForPickup' ||
				values.order.status === 'complete',
		},
	);

	// query for obtaining grab order
	const {
		data: grabOrder,
		refetch: refetchGrab,
		// isFetching: isFetchingGrab,
	} = useQuery(
		[`grab-${values.order.orderId}`, values.order.id],
		getGrabOrder,
		{
			placeholderData: {},
			initialData: {},
			onError: (err) => {
				if (err.response.status === 404) {
					console.log('no grab order for this order...'); // eslint-disable-line no-console
				}
			},
		},
	);

	const getGrabQuote = async (order) => {
		try {
			let codAmount = values.order.vendor.convenienceFee;
			if (values.order.paymentMethod === 'magpie') codAmount = 0;
			const { data } = await getQuote(codAmount, order);
			const quote = data.quotes[0].amount; // + 85
			setGrabQuote(quote);
			setTotalAmount(quote + convenienceFee);
			if (values.order.paymentMethod === 'magpie')
				setGrabQuoteMagpie(quote);
			else setGrabQuoteCOD(quote);
		} catch (err) {
			onError(err);
		}
	};

	useEffect(() => {
		setConvenienceFee(values.order.vendor.convenienceFee);
	}, [values.order.vendor.convenienceFee]);

	useEffect(() => {
		if (convenienceFee !== null) {
			setTotalAmount(null);
			if (values.order.paymentMethod === 'magpie') {
				if (grabQuoteMagpie) {
					setGrabQuote(grabQuoteMagpie);
					setTotalAmount(grabQuoteMagpie + convenienceFee);
				} else getGrabQuote(values.order);
			} else if (values.order.paymentMethod === 'cod') {
				if (grabQuoteCOD) {
					setGrabQuote(grabQuoteCOD);
					setTotalAmount(grabQuoteCOD + convenienceFee);
				} else getGrabQuote(values.order);
			}
		}
	}, [values.order.paymentMethod, convenienceFee]);

	const openBookingModal = () => {
		setSeconds(60);
		setBookingModalVisible(true);
		setIsActive(true);
	};

	const closeBookingModal = () => {
		setBookingModalVisible(false);
		setIsActive(false);
	};

	// function for getting delivery status
	const getGrabStatus = async (grabOrderStat) => {
		// get delivery status
		const newGrabOrder = await getDeliveryStatus(grabOrderStat.deliveryId);
		setDeliveryStatus(newGrabOrder);

		let payload = {
			deliveryId: newGrabOrder.deliveryID,
			status: newGrabOrder.status,
			// origin: newGrabOrder.quote.origin.address,
			// destination: newGrabOrder.quote.destination.address,
			// allocateTime: newGrabOrder.timeline.create,
			// pickupTime: newGrabOrder.timeline.pickup,
			// dropoffTime: newGrabOrder.timeline.dropoff,
			// amount: newGrabOrder.quote.amount,
			//   order:order.id,
			//   branch:order.branch.id,
			//   patient:order.patient.id,
			// trackingURL: newGrabOrder.trackingURL,
		};

		if (newGrabOrder.trackingURL) {
			payload.trackingURL = newGrabOrder.trackingURL;
		}

		if (newGrabOrder.invoiceNo) {
			payload.invoiceNo = newGrabOrder.invoiceNo;
		}

		if (
			newGrabOrder.status === 'ALLOCATING' ||
			newGrabOrder.status === 'QUEUEING'
		) {
			if (!isBookingModalVisible) {
				openBookingModal();
			}
			setRiderBooked(false);
		} else if (
			newGrabOrder.status === 'CANCELED' ||
			newGrabOrder.status === 'FAILED' ||
			newGrabOrder.status === 'RETURNED'
		) {
			setRiderBooked(false);
			setDeliveryStatus(null);
			closeBookingModal();
		} else {
			if (!riderBooked) {
				setRiderBooked(true);
				setGrabModalTitle('Request for Rider Success');
				setGrabModalContent('We found a Grab driver!');
				setGrabModalVisible(true);
			}
			closeBookingModal();
		}

		// setBookingModalVisible(false);

		if (newGrabOrder.courier) {
			payload = {
				...payload,
				licensePlate: newGrabOrder.courier.vehicle.licensePlate,
				courierName: newGrabOrder.courier.name,
				courierPhone: newGrabOrder.courier.phone,
			};
		}

		if (newGrabOrder.timeline) {
			payload = {
				...payload,
				allocateTime: newGrabOrder.timeline.create,
				pickupTime: newGrabOrder.timeline.pickup,
				dropoffTime: newGrabOrder.timeline.dropoff,
			};
		}

		// if (newGrabOrder.status === 'PICKING_UP') {
		// 	payload = {
		// 		...payload,
		// 		allocateTime: newGrabOrder.timeline.create,
		// 	};
		// }

		// if (newGrabOrder.status === 'IN_DELIVERY') {
		// 	payload = {
		// 		...payload,
		// 		pickupTime: newGrabOrder.timeline.pickup,
		// 	};
		// }

		// if (newGrabOrder.status === 'COMPLETED') {
		// 	payload = {
		// 		...payload,
		// 		dropoffTime: newGrabOrder.timeline.dropoff,
		// 	};
		// }

		// update strapi grab entry
		await updateGrabEntry(payload, grabOrder.id);
		await queryClient.resetQueries([
			`grab-${values.order.orderId}`,
			values.order.id,
		]);
	};

	const refreshStatus = async () => {
		if (!isEmpty(grabOrder)) {
			try {
				await getGrabStatus(grabOrder);
				await refetchFailed();
				await refetchGrab();
			} catch (err) {
				onError(err);
			}
		}
	};

	// status refresh
	useEffect(() => {
		let interval = null;
		if (riderBooked && deliveryStatus !== null) {
			interval = setInterval(() => {
				refreshStatus();
			}, 7000);
		}
		return () => clearInterval(interval);
	}, [riderBooked]);

	const cancelRider = async () => {
		try {
			await cancelDelivery(grabOrder.deliveryId);
			closeBookingModal();
			refreshStatus();
		} catch (err) {
			onError(err);
		}
	};

	// booking timer
	useEffect(() => {
		let interval = null;
		if (isActive) {
			interval = setInterval(() => {
				if (seconds <= 0) {
					if (!riderBooked && isBookingModalVisible) {
						cancelRider();
						setGrabModalTitle('Request for Rider Failed');
						setGrabModalContent(
							'We could not find a grab rider. Please try again.',
						);
						setGrabModalVisible(true);
					}
					closeBookingModal();
				} else if (seconds % 5 === 0) {
					getGrabStatus(grabOrder);
				}
				setSeconds((sec) => Math.max(sec - 1, 0));
			}, 1000);
		} else if (!isActive) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [isActive, seconds]);

	useEffect(() => {
		// if grab order exists, refresh
		if (!isEmpty(grabOrder) && !deliveryStatus) {
			refreshStatus();
		}
	}, [grabOrder]);

	useEffect(() => {
		if (!hasChanges) {
			handleChanges(keys(pickBy(touched, identity)).length > 0);
		}
	}, [touched]);

	const requestRider = async () => {
		try {
			await createDeliveryRequest(
				values.order,
				values.mobile,
				values.email,
				values.order.paymentMethod === 'magpie' ? 0 : totalAmount,
				refetchGrab,
			);
			// refresh
			refreshStatus();
		} catch (err) {
			onError(err);
		}
	};

	const togglePayment = () => setPaymentOpen(!paymentOpen);

	// const idPrefix = htmlIdGenerator()();
	// const [paymentMode, setPaymentMode] = useState(null);

	// const [checkboxes] = useState([
	// 	{
	// 		id: `${idPrefix}0`,
	// 		label: 'Option one',
	// 	},
	// 	{
	// 		id: `${idPrefix}1`,
	// 		label: 'Option two is checked by default',
	// 	},
	// 	{
	// 		id: `${idPrefix}2`,
	// 		label: 'Option three',
	// 	},
	// ]);

	const handleSaveAddress = () => {
		setEditableAddress(false);
	};

	const renderAddress = () => {
		// if (values.pickup) {
		// 	return {
		// 		title: <EuiFormLabel>City</EuiFormLabel>,
		// 		description: (
		// 			<CityMunicipalityDropdown
		// 				isInvalid={getInvalid('cityId', errors, touched)}
		// 				onChange={setCity}
		// 				value={values.cityId}
		// 			/>
		// 		),
		// 	};
		// }

		return {
			title: <EuiFormLabel>Address</EuiFormLabel>,
			description: !editableAddress ? (
				<>
					{(!!getInvalid('line1', errors, touched) ||
						!!getInvalid('brgyId', errors, touched) ||
						!!getInvalid('cityId', errors, touched)) && (
						<EuiCallOut
							color="danger"
							size="s"
							title="Complete address is Required"
						/>
					)}
					<div>
						{values?.deliveryAddress?.address ??
							buildLocalAddress({
								line1: values.deliveryAddress?.line1,
								line2: values.deliveryAddress?.line2,
								brgy: values.deliveryAddress?.brgy,
								city: values.deliveryAddress?.city,
							})}
					</div>
					{/* <EuiButtonIcon
						aria-label="Edit Address"
						display="base"
						iconType="pencil"
						onClick={() => setEditableAddress((isOn) => !isOn)}
						size="s"
					/> */}
				</>
			) : (
				<>
					<EuiHorizontalRule margin="s" />
					<AddressForm formikBag={formikBag} />
					<EuiFlexGroup
						direction="row"
						gutterSize="s"
						justifyContent="flexEnd"
						responsive="false"
					>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								aria-label="Close Edit Address"
								color="danger"
								display="fill"
								iconType="cross"
								onClick={() => setEditableAddress(false)}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								aria-label="Save Address"
								color="success"
								display="fill"
								iconType="check"
								onClick={handleSaveAddress}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiHorizontalRule margin="s" />
				</>
			),
		};
	};

	const renderAmounts = () => {
		let formList1 = [
			{
				title: <EuiFormLabel>Code</EuiFormLabel>,
				description: <b>{values.order.orderId}</b>,
			},
			// {
			// 	title: <EuiFormLabel>Fulfillment Type</EuiFormLabel>,
			// 	description: `${values.order.pickup ? 'PICKUP' : 'DELIVERY'}`,
			// },
			{
				title: <EuiFormLabel>Delivery Mode</EuiFormLabel>,
				description: (
					<strong
						style={{
							color:
								values.order.pickupType === 'grab'
									? 'forestgreen'
									: 'black',
						}}
					>
						{values.order.pickupType === 'grab'
							? 'GRAB DELIVERY'
							: 'BRANCH PICKUP'}
					</strong>
				),
			},
			// {
			// 	title: <EuiFormLabel>Mode of Payment</EuiFormLabel>,
			// 	description: `Cash on Delivery`,
			// },
		];

		if (!riderBooked) {
			formList1.push({
				title: <EuiFormLabel>Pickup</EuiFormLabel>,
				description: `${values.order.branch.name}`,
			});
		}

		if (values.order.isPaid) {
			formList1.push({
				title: <EuiFormLabel>Payment Method</EuiFormLabel>,
				description: `${values.order.paymentMethod}`,
			});
		}

		formList1.push(renderAddress());

		const formList2 = [
			{
				title: <EuiFormLabel>Medicine/s Total Amount</EuiFormLabel>,
				description: (
					<i style={{ color: 'gray' }}>Separate transaction</i>
				),
			},
			{
				title: <EuiFormLabel>Convenience & Delivery Fee</EuiFormLabel>,
				description: `₱${totalAmount ?? '---'}`,
			},
		];

		const formList3 = [
			{
				title: <EuiFormLabel>TOTAL AMOUNT</EuiFormLabel>,
				description: (
					<strong style={{ color: 'goldenrod' }}>
						₱
						{!isEmpty(grabOrder)
							? grabOrder.amount
							: totalAmount ?? '---'}
					</strong>
				),
			},
			{
				title: <EuiFormLabel>Status</EuiFormLabel>,
				description: (
					<i
						style={{
							color: values.order.isPaid ? 'forestgreen' : 'gray',
						}}
					>
						{values.order.isPaid ? 'Paid' : 'Unpaid'}
					</i>
				),
			},
		];

		let formListExtra = [];
		if (!isEmpty(failedGrabOrders)) {
			formListExtra = failedGrabOrders.map((gOrder) => {
				return {
					title: (
						<EuiFormLabel>
							{moment(gOrder.createdAt).format('LLL')}
						</EuiFormLabel>
					),
					description: `${gOrder.status}`,
				};
			});
		}

		const paymentMethods = [
			{
				id: 'magpie',
				label: (
					<span>
						{' '}
						Pay Now{' '}
						<span style={{ color: 'gray' }}>
							(Using GCash, Paymaya, BPI, or UnionBank)
						</span>
					</span>
				),
			},
			{
				id: 'cod',
				label: (
					<span>
						{' '}
						COD{' '}
						<span style={{ color: 'gray' }}>
							(Total amount will be collected by the rider)
						</span>
					</span>
				),
			},
			// { id: 'delivery', label: 'Pharmacy Delivery' },
		];

		if (!deliveryStatus) {
			return (
				<div>
					<EuiFormRow label="">
						<EuiDescriptionList
							compressed
							listItems={formList1}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>

					{!values.order.isPaid && (
						<EuiFormRow label="">
							<EuiRadioGroup
								idSelected={
									values.order.paymentMethod ??
									values.paymentMethod
								}
								legend={{
									children: <strong>Select Payment</strong>,
								}}
								name="paymentMethod"
								onChange={(id) => {
									setFieldValue('order.paymentMethod', id);
								}}
								options={paymentMethods}
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'left',
								}}
							/>
						</EuiFormRow>
					)}

					<EuiHorizontalRule margin="s" />

					{!riderBooked && (
						<>
							<EuiFormRow label="">
								<EuiTitle size="xs">
									<h6>SUMMARY</h6>
								</EuiTitle>
							</EuiFormRow>
							<EuiFormRow label="">
								<EuiDescriptionList
									compressed
									listItems={formList2}
									responsive="false"
									type="column"
								/>
							</EuiFormRow>
						</>
					)}

					<EuiHorizontalRule margin="s" />

					<EuiFormRow label="">
						<EuiDescriptionList
							compressed
							listItems={formList3}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>

					{formListExtra.length > 0 && (
						<EuiHorizontalRule margin="s" />
					)}

					{formListExtra.length > 0 && (
						<EuiFormRow label="">
							<EuiTitle size="xs">
								<h6>Grab Delivery History</h6>
							</EuiTitle>
						</EuiFormRow>
					)}

					{formListExtra.length > 0 && (
						<EuiFormRow label="">
							<EuiDescriptionList
								compressed
								listItems={formListExtra}
								responsive="false"
							/>
						</EuiFormRow>
					)}
				</div>
			);
		}

		if (!riderBooked) {
			return <></>;
		}
		const isPickingUpStatus = deliveryStatus.status === 'PICKING_UP';
		const isInDeliveryStatus = deliveryStatus.status === 'IN_DELIVERY';
		const deliveryTitle = isInDeliveryStatus ? 'Dropoff' : 'Completed';
		const etaTitle = isPickingUpStatus ? 'Pick Up' : deliveryTitle;

		const etaTimelinePickup = moment(deliveryStatus.timeline.pickup).format(
			'LLLL',
		);
		const etaTimelineDropoff = moment(
			deliveryStatus.timeline.dropoff,
		).format('LLLL');
		const etaTimelineCompleted = moment(
			deliveryStatus.timeline.completed,
		).format('LLLL');

		const deliveryDescription = isInDeliveryStatus
			? etaTimelineDropoff
			: etaTimelineCompleted;
		const etaDescription = isPickingUpStatus
			? etaTimelinePickup
			: deliveryDescription;

		if (riderBooked && deliveryStatus != null) {
			formList1 = [
				{
					title: <EuiFormLabel>Code</EuiFormLabel>,
					description: <b>{values.order.orderId}</b>,
				},
				{
					title: <EuiFormLabel>Grab Rider Name</EuiFormLabel>,
					description: `${deliveryStatus.courier.name}`,
				},
				{
					title: <EuiFormLabel>Contact Number</EuiFormLabel>,
					description: `${deliveryStatus.courier.phone}`,
				},
				{
					title: <EuiFormLabel>Status</EuiFormLabel>,
					description: `${deliveryStatus.status}`,
				},
				{
					title: <EuiFormLabel>ETA ({etaTitle})</EuiFormLabel>,
					description: etaDescription,
				},
				{
					title: <EuiFormLabel>Mode of Payment</EuiFormLabel>,
					description: 'Cash on Delivery',
				},
			];
		}

		const formList4 = [];
		if (riderBooked && deliveryStatus != null) {
			formList4.push({
				title: <EuiFormLabel>Pickup</EuiFormLabel>,
				description: `${deliveryStatus?.quote.origin.address}`,
			});
			formList4.push({
				title: <EuiFormLabel>Drop off</EuiFormLabel>,
				description: `${deliveryStatus?.quote.destination.address}`,
			});
			formList4.push({
				title: <EuiFormLabel>Tracking URL</EuiFormLabel>,
				description: (
					<a
						href={deliveryStatus?.trackingURL}
						rel="noreferrer"
						target="_blank"
					>
						Open in new tab
						<MdOpenInNew />
					</a>
				),
			});
		}

		return (
			<div>
				<EuiFormRow label="">
					<EuiDescriptionList
						listItems={formList1}
						responsive="false"
						type="column"
					/>
				</EuiFormRow>

				{/* <EuiHorizontalRule margin="s" /> */}
				{!riderBooked && (
					<EuiFormRow label="">
						<EuiDescriptionList
							listItems={formList2}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>
				)}

				<EuiHorizontalRule margin="s" />

				<EuiFormRow label="">
					<EuiDescriptionList
						listItems={formList3}
						responsive="false"
						type="column"
					/>
				</EuiFormRow>

				{riderBooked && (
					<>
						<EuiHorizontalRule margin="s" />

						<EuiFormRow label="">
							<EuiDescriptionList
								listItems={formList4}
								responsive="false"
								type="column"
							/>
						</EuiFormRow>
					</>
				)}
			</div>
		);
	};

	if (isLoading && !grabQuote) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: 200,
					minWidth: 254,
				}}
			>
				<EuiLoadingSpinner />
			</div>
		);
	}

	const handleToggleConfirmModal = async () => {
		if (values.order.isPaid || values.order.paymentMethod === 'cod')
			setConfirModalVisible((isOpen) => !isOpen);
		else if (values.order.paymentMethod === 'magpie') {
			setCreatingCheckout(true);
			const customerEmail = values.patient.contactDetails.find(
				(contact) => contact.type === 'email',
			);

			// call magpie endpoint
			const prescriptionCode =
				values.code && values.code !== ''
					? values.code
					: values.order?.prescription?.code;

			const res = await createCheckout({
				amount: totalAmount,
				customer_email: customerEmail ? customerEmail.value : '',
				lineItems: values.order.lineItems,
				orderId: values.order.orderId,
				code: prescriptionCode,
				token,
				returnPath: location.pathname,
			});

			const { payment_url: paymentUrl } = res;
			window.open(paymentUrl, '_blank');
			setCreatingCheckout(false);
		}
	};

	const handleToggleGrabModal = () => {
		setGrabModalVisible((isOpen) => !isOpen);
	};

	const handleConfirmModal = () => {
		requestRider();
		handleToggleConfirmModal();
	};

	const handleToggleCancelModal = () => {
		setCancelModalVisible((isOpen) => !isOpen);
	};

	const handleCancelModal = () => {
		cancelRider();
		handleToggleCancelModal();
	};

	const btnText = () => {
		if (values.order.isPaid || values.order.paymentMethod === 'cod') {
			return 'Request a Rider';
		}

		return 'Pay Now';
	};

	return (
		<EuiPageSection>
			<EuiForm>
				<EuiFlexGroup>
					<EuiFlexItem grow={false}>
						<EuiTitle size="s">
							<h4>Payment & Delivery Confirmation</h4>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiHorizontalRule margin="s" />

				{renderAmounts()}

				<EuiHorizontalRule margin="s" />

				<EuiFormRow>
					<EuiFlexGroup gutterSize="s" responsive={false}>
						{!riderBooked && (
							<>
								<EuiFlexItem>
									<EuiButtonEmpty
										className="danger-button-outlined"
										color="danger"
										disabled={isSubmitting}
										isLoading={isSubmitting}
										onClick={() => {
											setCancelTransactionModalVisible(
												true,
											);
										}}
									>
										Cancel
									</EuiButtonEmpty>
								</EuiFlexItem>
								<ConfirmationModal
									isVisible={isCancelTransactionModalVisible}
									message="Are you sure you want to cancel?"
									onClose={() =>
										setCancelTransactionModalVisible(false)
									}
									onConfirm={onClose}
									title="Confirm Cancellation"
								/>
							</>
						)}

						{!riderBooked && (
							<EuiFlexItem>
								<EuiButton
									color="primary"
									disabled={
										values.order.pickupType === 'branch' ||
										isSubmitting ||
										!grabQuote ||
										!values.order.paymentMethod ||
										isCreatingCheckout
									}
									fill
									isLoading={isCreatingCheckout}
									onClick={handleToggleConfirmModal}
								>
									{' '}
									{btnText()}
								</EuiButton>
							</EuiFlexItem>
						)}

						{riderBooked && deliveryStatus != null && (
							<EuiFlexItem>
								<EuiButton
									className="danger-button-outlined"
									color="danger"
									disabled={
										deliveryStatus.status !== 'PICKING_UP'
									}
									isLoading={null}
									onClick={handleToggleCancelModal}
								>
									Cancel Delivery
								</EuiButton>
							</EuiFlexItem>
						)}

						{riderBooked && deliveryStatus != null && (
							<EuiFlexItem>
								<EuiButton
									color="primary"
									disabled={
										deliveryStatus.status === 'COMPLETED'
									}
									fill
									isLoading={null}
									onClick={() => {
										window.open(
											`tel:+${deliveryStatus.courier.phone}`,
										);
									}}
								>
									Call Rider
								</EuiButton>
							</EuiFlexItem>
						)}
					</EuiFlexGroup>
				</EuiFormRow>
			</EuiForm>

			{/* <TermsModal isOpen={termOpen} onClose={toggleTerm} /> */}
			{/* <PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} /> */}

			<PaymentModal
				branch={values.order.branch}
				isOpen={paymentOpen}
				onClose={togglePayment}
			/>

			<ConfirmationModal
				isVisible={isConfirmationModalVisible}
				message="Do you want to request for a Grab Rider to deliver your order?"
				onClose={handleToggleConfirmModal}
				onConfirm={handleConfirmModal}
				title="Confirm"
			/>

			<BookingModal
				cancelDisable={isEmpty(grabOrder)}
				dropoff={buildLocalAddress({
					line1: values.line1,
					line2: values.line2,
					brgy: values.brgy,
					city: values.city,
				})}
				isVisible={isBookingModalVisible}
				message="We are finding you a rider"
				onCancel={handleToggleCancelModal}
				pickup={values.order.branch.name}
				seconds={seconds}
				// onRefresh={refreshStatus}
				// refreshDisable={isEmpty(grabOrder)}
				// onClose={handleToggleConfirmModal}
				// onConfirm={handleConfirmModal}
				title="Please wait..."
			/>

			<ConfirmationModal
				isVisible={isCancelModalVisible}
				message="Are you sure you want to cancel your order?"
				onClose={handleToggleCancelModal}
				onConfirm={handleCancelModal}
				title="Confirm Cancellation"
			/>

			<GrabModal
				isVisible={isGrabModalVisible}
				message={grabModalContent}
				onClose={handleToggleGrabModal}
				title={grabModalTitle}
			/>
		</EuiPageSection>
	);
};

RxPaymentForm.propTypes = {
	handleChanges: PropTypes.func,
	hasChanges: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	rx: PropTypes.shape({}).isRequired,
	token: PropTypes.string.isRequired,
};

RxPaymentForm.defaultProps = {
	handleChanges: () => {},
	hasChanges: false,
};

export default RxPaymentForm;
