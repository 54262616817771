/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable indent */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
	EuiTitle,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTextArea,
	EuiFieldText,
	EuiFieldNumber,
	EuiSpacer,
	EuiSuperSelect,
	EuiHealth,
} from '@elastic/eui';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { get, uniqueId } from 'lodash';

import { ADD_ITEM, EDIT_ITEM, ADD_PRESCRIPTION } from 'constants/prescription';
import ConfirmationModal from 'components/ConfirmationModal';
import ProductSearch from 'modules/_global/ProductSearch';
import { mapRxItemFormData } from '../prescriptions.fetch';
import PrescriptionItemsTable from './PrescriptionItemsTable';

const ItemFormSchema = Yup.object().shape({
	generic: Yup.string().required('Generic Product is required'),
	brand: Yup.string().required('Brand Name is required'),
	quantity: Yup.number()
		.min(1, 'Minimum quantity is 1')
		.default(0)
		.required('Quantity is required'),
});

const PrescriptionItemForm = ({
	formType,
	isEditable,
	isPatient,
	isVisible,
	onChange,
	onSubmit,
	prescriptionItems,
	...props
}) => {
	const [selectedRxItems, setSelectedRxItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [isConfirmDeleteModalVisisble, setIsConfirmDeleteModalVisible] =
		useState(false);
	const [isMultiDeleteConfirmModalVisible, setIsMultiDeleteConfirmModal] =
		useState(false);
	const searchInput = useRef(null);
	const quantiyInput = useRef();
	const genericNameInput = useRef();
	const [filter, setFilter] = useState('all');

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			mode: ADD_ITEM,
			id: '',
			product: null,
			generic: '',
			brand: '',
			strength: '',
			sig: '',
			quantity: '',
		},
		validationSchema: ItemFormSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			const itemFormData = {
				...mapRxItemFormData(data),
				id: data.id || uniqueId(),
				temp: !data.id,
			};

			if (data.mode === ADD_ITEM) {
				const updatedPrescriptionItems = [
					itemFormData,
					...prescriptionItems,
				];
				onSubmit(updatedPrescriptionItems);
			} else {
				onSubmit(
					prescriptionItems.map((item) => {
						if (item.id === data.id) {
							return {
								...item,
								...itemFormData,
								currentQty: itemFormData.quantity,
							};
						}

						return item;
					}),
				);
				searchInput.current.clear();
			}

			resetForm({ values: initialValues });
			searchInput.current.clear();
		},
	});

	const { brand, generic, mode, quantity, sig, strength } = values;

	useEffect(() => {
		onChange(values);
	}, [values]);

	useEffect(() => {
		resetForm({ values: initialValues });
		searchInput.current.clear();
		// subscribe to vendor if vendor selection based product search
	}, []);

	const handleEdit = (data) => {
		setFieldValue('mode', EDIT_ITEM);
		setFieldValue('id', data.id);
		setFieldValue('generic', data.generic);
		setFieldValue('brand', data.brand);
		setFieldValue('strength', data.strength);
		setFieldValue('quantity', data.quantity);
		setFieldValue('sig', data.sig);
		genericNameInput.current.focus();

		if (data.product) {
			searchInput.current.setValue({
				...data.product,
				label: data.product.name,
			});
		}
	};

	const handleDeleteItemConfirmModal = (data) => {
		setSelectedItem(data);
		setIsConfirmDeleteModalVisible(true);
	};

	const handleConfirmDelete = () => {
		onSubmit(
			prescriptionItems.filter((rxItem) => rxItem.id !== selectedItem.id),
		);
		setIsConfirmDeleteModalVisible(false);
		setSelectedItem(null);
	};

	const onSelect = useCallback((value) => {
		setFieldValue('generic', value.generic);
		setFieldValue(
			'brand',
			get(value, 'brand.name') || get(value, 'name') || 'Generic',
		);
		setFieldValue('strength', value.strength);
		setFieldValue('product', value);
		setTimeout(() => {
			quantiyInput.current.focus();
		}, 500);
	});

	const handleMultipleDelete = () => {
		const filteredRxItems = prescriptionItems.filter(
			(rxItem) =>
				!Object.keys(selectedRxItems)
					.map((key) => selectedRxItems[key].id)
					.includes(rxItem.id),
		);

		onSubmit(filteredRxItems);
		setIsMultiDeleteConfirmModal(false);
	};

	const handleMultiDeleteConfirmModal = () => {
		setIsMultiDeleteConfirmModal((isOpen) => !isOpen);
	};

	const handleCloseDeleteRxItemsModal = () => {
		setIsConfirmDeleteModalVisible(false);
		setSelectedItem(null);
	};

	const selection = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelectedRxItems(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selectedRxItems,
	};

	// const confirmDeleteModal = isConfirmDeleteModalVisisble ? (
	// 	<ConfirmationModal
	// 		message="Are you sure you want to do this?"
	// 		onClose={() => {
	// 			setIsConfirmDeleteModalVisible(false);
	// 		}}
	// 		onConfirm={() => {
	// 			removePrescriptionItem(selectedItem);
	// 			setIsConfirmDeleteModalVisible(false);
	// 			setSelectedItem(null);
	// 		}}
	// 		title="Delete Line Item"
	// 	/>
	// ) : null;

	if (!isVisible) {
		return null;
	}

	const isMobileView = () => {
		return window.innerWidth <= 600;
	};

	return (
		<>
			<EuiTitle size="xs">
				<h3>
					{mode === ADD_ITEM && !isPatient && 'Add Item'}
					{mode !== ADD_ITEM && !isPatient && 'Update Item'}
					{mode === ADD_ITEM && isPatient && 'Add Additional Items'}
				</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="xs" />
			<EuiForm>
				<EuiSpacer size="s" />
				<EuiFlexGroup alignItems="flexEnd">
					<EuiFlexItem>
						<EuiFormRow label="Search Product">
							<ProductSearch
								ref={searchInput}
								filter={filter !== 'all' ? filter : null}
								onSelect={onSelect}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow>
							<EuiSuperSelect
								// disabled={isPatient}
								onChange={setFilter}
								options={[
									{
										value: 'all',
										inputDisplay: (
											<EuiHealth
												color="subdued"
												style={{
													marginBottom: 0,
												}}
											>
												All
											</EuiHealth>
										),
									},
									{
										value: 'rx',
										inputDisplay: (
											<EuiHealth
												color="success"
												style={{
													marginBottom: 0,
												}}
											>
												Rx (Prescription)
											</EuiHealth>
										),
									},
									{
										value: 'non-rx',
										inputDisplay: (
											<EuiHealth
												color="warning"
												style={{
													marginBottom: 0,
												}}
											>
												OTC (Over the Counter)
											</EuiHealth>
										),
									},
								]}
								valueOfSelected={filter}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiSpacer />
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiFormRow
							error={touched.generic && errors.generic}
							isInvalid={touched.generic && !!errors.generic}
							label="Generic Name"
						>
							<EuiFieldText
								data-testid="generic-input"
								id="generic"
								inputRef={genericNameInput}
								isInvalid={touched.generic && !!errors.generic}
								name="generic"
								onChange={handleChange}
								placeholder="Generic Product"
								value={generic}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow
							error={touched.brand && errors.brand}
							isInvalid={touched.brand && !!errors.brand}
							label="Brand"
						>
							<EuiFieldText
								data-testid="brand-input"
								id="brand"
								isInvalid={touched.brand && !!errors.brand}
								name="brand"
								onChange={handleChange}
								placeholder="Brand"
								value={brand}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiSpacer />
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiFormRow
							error={touched.strength && errors.strength}
							isInvalid={touched.strength && !!errors.strength}
							label="Strength"
						>
							<EuiFieldText
								data-testid="strength-input"
								id="strength"
								isInvalid={
									touched.strength && !!errors.strength
								}
								name="strength"
								onChange={handleChange}
								placeholder="Strength"
								value={strength}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow
							error={touched.quantity && errors.quantity}
							helpText={
								mode === EDIT_ITEM
									? 'Updating quantity will reset the current quantity'
									: ''
							}
							isInvalid={touched.quantity && !!errors.quantity}
							label="Quantity"
						>
							<EuiFieldNumber
								data-testid="quantity-input"
								id="quantity"
								inputRef={quantiyInput}
								isInvalid={
									touched.quantity && !!errors.quantity
								}
								name="quantity"
								onChange={handleChange}
								placeholder="Quantity"
								value={quantity}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiFlexGroup>
					{isMobileView() && (
						<EuiFlexItem style={{ flex: 1 }}>
							{!isPatient && (
								<EuiFormRow
									// error={touched.sig && errors.sig}
									// isInvalid={touched.sig && !!errors.sig}
									label="SIG"
								>
									<EuiTextArea
										data-testid="sig-input"
										disabled={formType !== ADD_PRESCRIPTION}
										id="sig"
										name="sig"
										// isInvalid={touched.sig && !!errors.sig}
										onChange={handleChange}
										placeholder="SIG"
										style={{ height: '120px' }}
										value={sig}
									/>
								</EuiFormRow>
							)}
						</EuiFlexItem>
					)}
					{!isMobileView() && (
						<EuiFlexItem style={{ flex: 1 }}>
							{!isPatient && (
								<EuiFormRow
									// error={touched.sig && errors.sig}
									// isInvalid={touched.sig && !!errors.sig}
									label="SIG"
								>
									<EuiTextArea
										data-testid="sig-input"
										disabled={formType !== ADD_PRESCRIPTION}
										id="sig"
										name="sig"
										// isInvalid={touched.sig && !!errors.sig}
										onChange={handleChange}
										placeholder="SIG"
										style={{ height: '120px' }}
										value={sig}
									/>
								</EuiFormRow>
							)}
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
				<EuiSpacer />
				<EuiFlexGroup>
					<EuiFlexItem style={{ flex: 1, alignItems: 'flex-end' }}>
						<EuiFormRow>
							<EuiFlexGroup
								gutterSize="s"
								justifyContent="flexEnd"
								responsive={false}
							>
								<EuiFlexItem>
									<EuiButton
										color="danger"
										fullWidth={false}
										iconType="broom"
										onClick={() => {
											resetForm({
												values: initialValues,
											});
											searchInput.current.clear();
										}}
										size="s"
									>
										Clear
									</EuiButton>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiButton
										fullWidth={false}
										iconType="plusInCircle"
										onClick={handleSubmit}
										size="s"
									>
										{`${
											mode === ADD_ITEM
												? 'Add Item'
												: 'Update Item'
										}`}
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiForm>
			<EuiSpacer size="l" />
			<EuiHorizontalRule margin="s" />
			<EuiSpacer size="l" />
			<EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
				{selectedRxItems.length ? (
					<EuiFlexItem grow={false}>
						<EuiButton
							color="danger"
							fill={false}
							iconType="trash"
							onClick={handleMultiDeleteConfirmModal}
							size="s"
						>
							Delete
						</EuiButton>
					</EuiFlexItem>
				) : null}
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h1>Items Added</h1>
					</EuiTitle>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="s" />
			<PrescriptionItemsTable
				{...props}
				isEditable={isEditable}
				isPatient={isPatient}
				isSelectable
				items={prescriptionItems}
				onDelete={handleDeleteItemConfirmModal}
				onEdit={handleEdit}
				selection={selection}
			/>
			<EuiSpacer size="l" />
			<ConfirmationModal
				isVisible={isConfirmDeleteModalVisisble}
				message="Are you sure you want to do this?"
				onClose={() => {
					setIsConfirmDeleteModalVisible(false);
				}}
				onConfirm={handleConfirmDelete}
				title="Delete Item"
			/>
			<ConfirmationModal
				isVisible={isMultiDeleteConfirmModalVisible}
				message="Are you sure you want to do this?"
				onClose={handleCloseDeleteRxItemsModal}
				onConfirm={handleMultipleDelete}
				title="Delete Items"
			/>
		</>
	);
};

PrescriptionItemForm.propTypes = {
	formType: PropTypes.string,
	isEditable: PropTypes.bool,
	isPatient: PropTypes.bool,
	isVisible: PropTypes.bool,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	prescriptionItems: PropTypes.array,
};

PrescriptionItemForm.defaultProps = {
	formType: null,
	isEditable: true,
	isPatient: false,
	isVisible: true,
	onChange: () => {},
	onSubmit: () => {},
	prescriptionItems: [],
};

export default PrescriptionItemForm;
