/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FaFilePrescription } from 'react-icons/fa';
import {
	FiBox,
	FiHome,
	FiPackage,
	FiUsers,
	FiUser,
	FiFileText,
	FiSettings,
} from 'react-icons/fi';

import _ from 'lodash';

import Dashboard from 'modules/dashboard/Dashboard';
import Order from 'modules/orders/Order';
import Orders from 'modules/orders/Orders';
import AddOrder from 'modules/orders/AddOrder';
import Product from 'modules/products/Product';
import Products from 'modules/products/Products';
import AddEncodedPrescription from 'modules/prescriptions/AddEncodedPrescription';
import Prescriptions from 'modules/prescriptions/Prescriptions';
import Prescription from 'modules/prescriptions/Prescription';
import Users from 'modules/users/Users';
import User from 'modules/users/User';
import Patients from 'modules/patients/Patients';
import Patient from 'modules/patients/Patient';
import Settings from 'modules/settings/Settings';
import DoctorSettings from 'modules/doctors/DoctorSettings';
import Secretary from 'modules/secretaries/Secretary';
import Branch from 'modules/branches/Branch';
import PatientDashboardContent from 'modules/dashboard/PatientDashboardContent';
import PatientOrder from 'modules/patients/PatientOrder';
import PatientTransaction from 'modules/patients/PatientTransaction';
import ViewOrder from 'modules/patients/ViewOrder';

import {
	SUPER_ADMIN,
	DOCTOR,
	PHARMACIST,
	ADMIN,
	SECRETARY,
	CALL_CENTER,
	PATIENT,
} from './roles';

export const menu = (t, vendorOpts) => [
	{
		component: Dashboard,
		exact: true,
		path: '/',
		name: t('dashboard.title').toLowerCase(),
		icon: () => <FiHome size={24} />,
		title: t('dashboard.title'),
		users: [
			SUPER_ADMIN.key,
			DOCTOR.key,
			SECRETARY.key,
			PHARMACIST.key,
			ADMIN.key,
			CALL_CENTER.key,
		],
	},
	{
		component: Prescriptions,
		path: '/prescriptions',
		inMenu: true,
		exact: true,
		icon: () => <FaFilePrescription size={24} />,
		name: t('prescriptions.title').toLowerCase(),
		title: t('prescriptions.title'),
		users: [
			SUPER_ADMIN.key,
			DOCTOR.key,
			PHARMACIST.key,
			ADMIN.key,
			SECRETARY.key,
			CALL_CENTER.key,
		],
	},
	{
		component: AddEncodedPrescription,
		path: '/prescriptions/create',
		inMenu: false,
		users: [SECRETARY.key, DOCTOR.key],
	},
	{
		component: Prescription,
		path: '/prescriptions/:prescriptionId',
		inMenu: false,
		users: [
			SUPER_ADMIN.key,
			DOCTOR.key,
			PHARMACIST.key,
			ADMIN.key,
			SECRETARY.key,
			CALL_CENTER.key,
		],
	},
	{
		component: Orders,
		path: '/orders',
		exact: true,
		name: t('orders.title').toLowerCase(),
		icon: () => <FiPackage size={24} />,
		title: t('orders.title'),
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key, CALL_CENTER.key],
		inMenu: _.get(vendorOpts, 'orderingEnabled'),
	},
	{
		component: Order,
		path: '/orders/:orderId',
		inMenu: false,
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key, CALL_CENTER.key],
	},
	{
		component: AddOrder,
		path: '/orders/create',
		inMenu: false,
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key, CALL_CENTER.key],
	},
	{
		component: Products,
		path: '/products',
		exact: true,
		name: t('products.title').toLowerCase(),
		icon: () => <FiBox size={24} />,
		title: t('products.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: Product,
		path: '/products/:productId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
	{
		component: Users,
		path: '/users',
		exact: true,
		name: t('users.title').toLowerCase(),
		icon: () => <FiUser size={24} />,
		title: t('users.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: User,
		path: '/users/:userId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
	{
		component: Patients,
		path: '/patients',
		exact: true,
		name: t('patients.title').toLowerCase(),
		icon: () => <FiUsers size={24} />,
		title: t('patients.title'),
		users: [DOCTOR.key, SECRETARY.key],
	},
	{
		component: Patient,
		path: '/patients/:patientId',
		inMenu: false,
		users: [DOCTOR.key, SECRETARY.key],
	},
	{
		component: Dashboard,
		path: '/special-recommendations',
		exact: true,
		name: 'Special Recommendations',
		icon: () => <FiFileText size={24} />,
		title: 'Special Recommendations',
		users: [DOCTOR.key],
	},
	{
		component: Secretary,
		path: '/secretaries/:secretaryId',
		inMenu: false,
		users: [DOCTOR.key],
	},
	{
		component: Settings,
		path: '/settings',
		exact: true,
		name: t('settings.title').toLowerCase(),
		icon: () => <FiSettings size={24} />,
		title: t('settings.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: DoctorSettings,
		path: '/settings',
		exact: true,
		name: t('settings.title').toLowerCase(),
		icon: () => <FiSettings size={24} />,
		title: t('settings.title'),
		users: [DOCTOR.key],
	},
	{
		component: Branch,
		path: '/branches/:branchId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
	{
		component: PatientOrder,
		path: '/my-orders/create',
		inMenu: false,
		users: [PATIENT.key],
	},
	{
		component: ViewOrder,
		path: '/orders/:orderId',
		inMenu: false,
		users: [PATIENT.key],
	},
	{
		component: PatientTransaction,
		path: '/transactions/:orderId',
		inMenu: false,
		users: [PATIENT.key],
	},
	{
		component: PatientDashboardContent,
		path: '/',
		inMenu: false,
		users: [PATIENT.key],
	},
];
