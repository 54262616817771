import * as yup from 'yup';
import { get } from 'lodash';
import { ENDPOINTS } from 'constants/api';
import { thenCallbackRQ } from 'utils/helpers';
import api from 'utils/api';

export const validateRxModel = {
	code: '',
	doctor: {},
	patient: {},
	fulfillment: 'purchase_all',
	paymentMethod: 'gcash',
	patientNotes: '',
	clinic: {},
	createdAt: null,
	type: null,
	prescriptionItems: [],
	status: '',
	attachments: [],
	additionalAttachments: [],
	deletedFiles: [],
	orders: [],
	branch: null,
	preferredBranch: null,
	pickup: true,
	vendor: null,
	line1: '',
	line2: '',
	city: '',
	cityId: '',
	brgy: '',
	brgyId: '',
	email: '',
	mobile: '',
	order: null,
	proofOfPayment: [],
	pickupType: '',
	preferredPickupType: '',
	deliveryAddress: {},
};

export const validateRxValidator = yup.object().shape({
	pickup: yup.bool().default(false),
	line1: yup
		.string()
		.when('pickup', {
			is: false,
			then: yup.string().required(),
		})
		.nullable(),
	city: yup.string().when('pickup', {
		is: false,
		then: yup.string().required(),
	}),
	cityId: yup.string().when('pickup', {
		is: false,
		then: yup.string().required(),
	}),
	brgy: yup
		.string()
		.when('pickup', {
			is: false,
			then: yup.string().required(),
		})
		.nullable(),
	brgyId: yup
		.string()
		.when('pickup', {
			is: false,
			then: yup.string().required(),
		})
		.nullable(),
	email: yup.string().email('Invalid email').required(),
	mobile: yup.number().typeError('Invalid mobile number').required(),
	vendor: yup.string().required(),
	branch: yup.string().required(),
});

export const mapValidateRxData = (rxData) => {
	const data = {
		...validateRxModel,
		code: rxData.code || '',
		doctor: rxData.doctor || {},
		patient: rxData.patient || {},
		fulfillment: get(rxData, 'fulfillment', 'purchase_all'),
		paymentMethod: get(rxData, 'paymentMethod', 'gcash'),
		patientNotes: rxData.patientNotes || '',
		clinic: rxData.clinic || {},
		createdAt: rxData.createdAt || null,
		type: rxData.type || null,
		prescriptionItems: rxData.prescriptionItems || [],
		status: rxData.status || '',
		attachments: rxData.attachments || [],
		additionalAttachments: rxData.additionalAttachments || [],
		branch: get(rxData, 'branch.id') || null,
		preferredBranch: get(rxData, 'preferredBranch.id') || null,
		pickup: rxData.pickup,
		vendor: get(rxData, 'vendor.id') || null,
		line1: get(rxData, 'patient.address.line1', ''),
		line2: get(rxData, 'patient.address.line2', ''),
		city: get(rxData, 'patient.address.city', ''),
		cityId: get(rxData, 'patient.address.cityId', ''),
		brgyId: get(rxData, 'patient.address.brgyId', ''),
		contactDetails: get(rxData, 'patient.contactDetails', ''),
		order: rxData.order,
		proofOfPayment: rxData.order?.proofOfPayment,
		pickupType: rxData.order?.pickupType,
		preferredPickupType: rxData.preferredPickupType,
		deliveryAddress: get(rxData, 'deliveryAddress', null),
	};

	data.email = data.contactDetails.find(
		(contactDetail) => contactDetail.type === 'email',
	)?.value;
	data.mobile = data.contactDetails.find(
		(contactDetail) => contactDetail.type === 'mobile',
	)?.value;
	delete data.contactDetails;

	return data;
};

export const buildFormData = (data) => {
	const build = {
		code: data.code,
		patient: {
			id: data.patient.id,
			contactDetails: data.contactDetails,
			address: {
				id: get(data, 'patient.address.id', null),
				line1: data.line1,
				line2: data.line2,
				city: data.city,
				cityId: data.cityId,
				brgy: data.brgy,
				brgyId: data.brgyId,
			},
		},
		fulfillment: data.fulfillment,
		paymentMethod: data.paymentMethod,
		patientNotes: data.patientNotes,
		additionalAttachments: data.additionalAttachments,
		deletedFiles: data.deletedFiles,
		type: data.type,
		branch: data.branch,
		preferredBranch: data.preferredBranch,
		pickup: data.pickup,
		vendor: data.vendor,
		preferredPickupType: data.preferredPickupType,
		deliveryAddress: data.deliveryAddress,
	};

	const contactDetails = [];
	if (data.email) {
		contactDetails.push({
			type: 'email',
			value: data.email,
		});
	}
	if (data.mobile) {
		contactDetails.push({
			type: 'mobile',
			value: data.mobile,
		});
	}

	if (contactDetails.length) {
		build.patient.contactDetails = contactDetails;
	}

	return build;
};

export const getRxByCode = (payload) => {
	return api
		.post(`${ENDPOINTS.PRESCRIPTIONS}/validate`, payload)
		.then(thenCallbackRQ);
};

export const getConfirmRx = (ctx) => {
	const { queryKey } = ctx;
	const [, token] = queryKey;
	sessionStorage.setItem('validate-rx-token', token);

	const response = api
		.get(`${ENDPOINTS.PRESCRIPTIONS}/confirm`, {
			params: {
				token,
			},
		})
		.then(({ data }) => {
			sessionStorage.setItem('validate-rx-data', JSON.stringify(data));
			return data;
		});

	return response;
};

export const putConfirmRx = (payload) => {
	const { additionalAttachments, token, ...data } = payload;
	const buildedFormData = buildFormData(data);

	const formData = new FormData();

	formData.append('data', JSON.stringify(buildedFormData));

	additionalAttachments.forEach((file) =>
		formData.append('files[additionalAttachments]', file),
	);

	return api
		.put(`${ENDPOINTS.PRESCRIPTIONS}/confirm`, formData, {
			params: { token },
		})
		.then(thenCallbackRQ);
};

export const buildPaymentFormData = (data, proof) => {
	return {
		order: data.order,
		proofOfPayment: proof,
	};
};

export const putPaymentRx = (payload) => {
	const { proofOfPayment, token, ...data } = payload;
	// console.log(payload)
	const buildedFormData = buildPaymentFormData(data, proofOfPayment);
	// console.log(buildedFormData)

	const formData = new FormData();

	formData.append('data', JSON.stringify(buildedFormData));

	proofOfPayment.forEach((file) =>
		formData.append('files[proofOfPayment]', file),
	);

	return api
		.put(`${ENDPOINTS.ORDERS}/pay`, formData, {
			params: { token },
		})
		.then(thenCallbackRQ);
};

export const putPaymentMagpie = (payload) => {
	const { orderId, token } = payload;
	const body = { orderId };
	return api
		.put(`${ENDPOINTS.ORDERS}/pay-magpie`, body, {
			params: { token },
		})
		.then(thenCallbackRQ);
};

export const renewRX = async (token) => {
	return api.put(`/prescriptions/renew`, null, { params: { token } });
};

export const getGeocode = (payload) => {
	return api.post('grab-orders/geocode', payload).then(thenCallbackRQ);
};

export const createCheckout = (payload) => {
	return api.post('orders/create-checkout', payload).then(thenCallbackRQ);
};
